import { Currency } from '@uniswap/sdk'
import React from 'react'
import styled from 'styled-components'
import flowIcon from '../../assets/images/flow-logo.svg'
import usdtIcon from '../../assets/images/usdt.png'
import tusdtIcon from '../../assets/images/tUSDT.png'
import fusdIcon from '../../assets/images/fusd-logo.png'
import bltIcon from '../../assets/images/blt-logo.png'
import { TokenInfo, TokenInfoName } from '../../types'

import Logo from '../Logo'

const images: { [tokenName: string]: string } = {
  FLOW: flowIcon,
  tUSDT: tusdtIcon,
  USDT: usdtIcon,
  FUSD: fusdIcon,
  BLT: bltIcon
}

const StyledLogo = styled(Logo)<{ size: string; placetop: any }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  ${({ placetop }) =>
    placetop &&
    `
    align-self: flex-start;
    margin-top: 2px;
  `}
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  placetop = false,
  style,
  ...rest
}: {
  currency?: TokenInfo | TokenInfoName | Currency
  size?: string
  placetop?: any
  style?: React.CSSProperties
}) {
  return (
    <StyledLogo
      size={size}
      placetop={placetop ? 1 : undefined}
      srcs={[images[currency?.symbol ?? 'FLOW']]}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
      {...rest}
    />
  )
}
