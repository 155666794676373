import { ChainId } from '@uniswap/sdk'
import { useEffect, useState } from 'react'
import { useFclReact, useTokenBalances } from '../../fcl-react'
import useTimer from '../../hooks/useTimer'
import { useWalletActionHandlers } from './flowHooks'
import { useAllCompletedTransactions } from '../transactionsFlow/hooks'

export default function Updater(): null {
  const { chainId, account, connect, disconnect } = useFclReact()
  const [loaded, setLoaded] = useState<ChainId>()
  const nonce = useTimer(10000)
  const allCompletedTxs = useAllCompletedTransactions()
  const balances = useTokenBalances(account, nonce + allCompletedTxs.length)
  const { onUpdateBalances } = useWalletActionHandlers()

  useEffect(() => {
    if (!chainId || !connect || !disconnect) return

    if (loaded && chainId !== loaded) {
      disconnect()
      connect()
    }

    if (chainId && !loaded) {
      connect()
    }

    setLoaded(chainId)
  }, [chainId, loaded, connect, disconnect])

  useEffect(() => {
    onUpdateBalances(balances)
  }, [balances, onUpdateBalances])

  return null
}
