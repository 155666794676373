import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'

import { useFclReact, useTotalSupply, usePoolAmounts } from '../../fcl-react'
import { useTokenBalance } from '../../state/wallet/flowHooks'
import { TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { ButtonPrimary, ButtonEmpty } from '../Button'
import { Pair } from '../../types'

import { useColor } from '../../hooks/useColor'

import Card, { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)<{ onClick?: Function }>`
  height: 24px;
  cursor: ${({ onClick }) => (Boolean(onClick) ? 'pointer' : 'inherit')};
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  border: solid 1px white;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  overflow: hidden;
`

const Separator = styled.div`
  width: calc(100% + 2.5rem);
  height: 1px;
  margin: 0 -1.25rem 10px -1.25rem;
  background-color: ${({ theme }) => theme.bg3};
`

interface PositionCardProps {
  pair: Pair
  border?: string
}

export function MinimalPositionCard({ pair, border }: PositionCardProps) {
  const { account } = useFclReact()

  const currency0 = pair.token0
  const currency1 = pair.token1

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && totalPoolTokens > userPoolBalance
      ? userPoolBalance / totalPoolTokens
      : undefined

  const poolAmounts = usePoolAmounts(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair && !!totalPoolTokens && !!userPoolBalance && !!poolTokenPercentage
      ? [
          poolAmounts[pair.token0.symbol ?? ''] * poolTokenPercentage,
          poolAmounts[pair.token1.symbol ?? ''] * poolTokenPercentage
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && userPoolBalance > 0 ? (
        <LightCard border={border}>
          <AutoColumn gap="10px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={400} fontSize={14}>
                  Your position
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={600} fontSize={20}>
                  {currency0.symbol} / {currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={600} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toFixed(8) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={400}>
                  Your pool share:
                </Text>
                <Text fontSize={14} fontWeight={500}>
                  {poolTokenPercentage ? (poolTokenPercentage * 100).toFixed(4) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={400}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toFixed(8)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={400}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toFixed(8)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </LightCard>
      ) : (
        <LightCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
            By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border }: PositionCardProps) {
  const { account } = useFclReact()

  const currency0 = pair.token0
  const currency1 = pair.token1

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && totalPoolTokens > userPoolBalance
      ? userPoolBalance / totalPoolTokens
      : undefined

  const poolAmounts = usePoolAmounts(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair && !!totalPoolTokens && !!userPoolBalance && !!poolTokenPercentage
      ? [
          poolAmounts[pair.token0.symbol ?? ''] * poolTokenPercentage,
          poolAmounts[pair.token1.symbol ?? ''] * poolTokenPercentage
        ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <AutoColumn gap="16px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={24} />
            <Text fontWeight={500} fontSize={16}>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol} / ${currency1.symbol}`}
            </Text>
          </RowFixed>

          <RowFixed gap="8px">
            <ButtonEmpty padding="6px 8px" borderRadius="12px" width="fit-content">
              <ChevronUp
                size="20"
                style={{
                  marginLeft: '10px',
                  transition: '.2s transform',
                  transform: showMore ? 'rotate(0)' : 'rotate(180deg)'
                }}
              />
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="10px">
            <Separator />

            <FixedHeightRow>
              <Text fontSize={14} fontWeight={500}>
                Your pool tokens:
              </Text>
              <Text fontSize={14} fontWeight={400}>
                {userPoolBalance ? userPoolBalance.toFixed(8) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={14} fontWeight={500}>
                  Pooled {currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={14} fontWeight={400} marginLeft={'6px'}>
                    {token0Deposited?.toFixed(8)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={14} fontWeight={500}>
                  Pooled {currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={14} fontWeight={400} marginLeft={'6px'}>
                    {token1Deposited?.toFixed(8)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <Text fontSize={14} fontWeight={500}>
                Your pool share:
              </Text>
              <Text fontSize={14} fontWeight={400}>
                {poolTokenPercentage ? (poolTokenPercentage * 100).toFixed(4) + '%' : '-'}
              </Text>
            </FixedHeightRow>
            <RowBetween marginTop="10px">
              <ButtonPrimary
                padding="10px"
                borderRadius="8px"
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="48%"
              >
                Add
              </ButtonPrimary>
              <ButtonPrimary
                padding="10px"
                borderRadius="8px"
                as={Link}
                width="48%"
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                Remove
              </ButtonPrimary>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
