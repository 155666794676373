import { createContext } from 'react'

interface SolanaContextInterface {
  solana: any
  setSolana?: (s: any) => void
}

export const SolanaContext = createContext<SolanaContextInterface | null>({
  solana: null,
  setSolana: () => null
})
