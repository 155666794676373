import { useMemo, useState, useEffect } from 'react'
import { TradeType } from '@uniswap/sdk'
import { TokenInfo } from '../types'
import { TRADE_ROUTES } from '../constants/lists'
import { Trade } from './types'
import { useFclReact } from './useFclReact'
import getExactIn from './scripts/getExactIn'
import getExactOut from './scripts/getExactOut'
import { replaceContractAddresses } from './env'

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(amountIn?: number, currencyIn?: TokenInfo, currencyOut?: TokenInfo): Trade | undefined {
  const { fcl, types, chainId } = useFclReact()
  const [trade, setTrade] = useState<Trade | undefined>()

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!currencyIn?.symbol || !currencyOut?.symbol || !amountIn) {
      return callback
    }

    const script = replaceContractAddresses(getExactIn[currencyIn?.symbol]?.[currencyOut?.symbol] ?? '', chainId)

    if (!script) {
      return callback
    }

    const routes = TRADE_ROUTES[chainId]

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(amountIn.toFixed(8), types.UFix64)])])
      .then(fcl.decode)
      .then(([quote, currentPriceRaw]: [string, string]) => {
        const amountOut = parseFloat(quote)
        const currentPrice = parseFloat(currentPriceRaw)
        const executionPrice = amountOut / amountIn
        const priceImpactParsed = (Math.max(currentPrice - executionPrice, 0) / currentPrice) * 100

        isSubscribed &&
          setTrade({
            tradeType: TradeType.EXACT_INPUT,
            inputCurrency: currencyIn,
            outputCurrency: currencyOut,
            route: routes[`${currencyIn.address}:${currencyOut.address}`] || [currencyIn, currencyOut],
            inputAmount: amountIn,
            outputAmount: amountOut,
            executionPrice: executionPrice,
            priceImpact: priceImpactParsed
          })
      })
      .catch((error: Error) => {
        console.log(error)

        setTrade({
          tradeType: TradeType.EXACT_INPUT,
          inputCurrency: currencyIn,
          outputCurrency: currencyOut,
          route: routes[`${currencyIn.address}:${currencyOut.address}`] || [currencyIn, currencyOut],
          inputAmount: amountIn,
          outputAmount: 0,
          executionPrice: 0,
          priceImpact: 100
        })
      })

    return callback
  }, [amountIn, currencyIn, currencyOut, fcl, types, chainId])

  return useMemo(() => trade, [trade])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  amountOut?: number,
  currencyIn?: TokenInfo,
  currencyOut?: TokenInfo
): Trade | undefined {
  const { fcl, types, chainId } = useFclReact()
  const [trade, setTrade] = useState<Trade | undefined>()

  useEffect(() => {
    if (!currencyIn?.symbol || !currencyOut?.symbol || !amountOut) {
      return
    }

    const script = replaceContractAddresses(getExactOut[currencyIn?.symbol]?.[currencyOut?.symbol] ?? '', chainId)

    if (!script) {
      return
    }

    const routes = TRADE_ROUTES[chainId]

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(amountOut.toFixed(8), types.UFix64)])])
      .then(fcl.decode)
      .then(([quote, currentPriceRaw]: [string, string]) => {
        const amountIn = parseFloat(quote)
        const currentPrice = parseFloat(currentPriceRaw)
        const executionPrice = amountOut / amountIn
        const priceImpactParsed = (Math.max(currentPrice - executionPrice, 0) / currentPrice) * 100

        setTrade({
          tradeType: TradeType.EXACT_OUTPUT,
          inputCurrency: currencyIn,
          outputCurrency: currencyOut,
          route: routes[`${currencyIn.address}:${currencyOut.address}`] || [currencyIn, currencyOut],
          inputAmount: amountIn,
          outputAmount: amountOut,
          executionPrice: executionPrice,
          priceImpact: priceImpactParsed
        })
      })
      .catch((error: Error) => {
        console.log(error)

        setTrade({
          tradeType: TradeType.EXACT_OUTPUT,
          inputCurrency: currencyIn,
          outputCurrency: currencyOut,
          route: routes[`${currencyIn.address}:${currencyOut.address}`] || [currencyIn, currencyOut],
          inputAmount: 0,
          outputAmount: amountOut,
          executionPrice: 0,
          priceImpact: 100
        })
      })
  }, [amountOut, currencyIn, currencyOut, fcl, types, chainId])

  return useMemo(() => trade, [trade])
}
