import { Currency } from '@uniswap/sdk'
import { TokenInfo } from '../types'

export function useColor(token?: TokenInfo | Currency): string {
  const mapping: { [tokenName: string]: string } = {
    FLOW: '#2172E5',
    TUSDT: '#2172E5',
    'FLOW:tUSDT': '#2172E5'
  }

  return mapping[token?.symbol ?? 'FLOW']
}
