import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useFclReact } from '../../fcl-react'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactionsFlow/actions'
import { AutoRow, HeaderRow as Row } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'

import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getFvsLink } from '../../utils'
import flowIcon from '../../assets/images/flow-logo.svg'
import { ButtonSmall } from '../Button'
import { LinkStyledButton, TYPE } from '../../theme'

const HeaderRow = styled(Row)`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem;
  font-weight: 600;
  justify-content: center;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1.5rem 1rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
  gap: 1rem;
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 0.5rem 1.5rem;
  flex-grow: 1;
  display: flex;
  background-color: ${({ theme }) => theme.bg1};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
`

const TransactionList = styled.div`
  flex-grow: 1;
  padding: 0.5rem 0;
  overflow: auto;
`

const AccountControl = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  cursor: pointer;
  z-index: 1;
  transition: 0.2s transform;

  &:hover {
    transform: scale(1.05);
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const WalletAddress = styled.a`
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
  }
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled(ButtonSmall)`
  flex: 0 0 auto;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
`

const Separator = styled.div`
  width: calc(100% + 3rem);
  height: 1px;
  margin: 0 -1.5rem;
  background-color: ${({ theme }) => theme.bg3};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions
}: AccountDetailsProps) {
  const { chainId, account, disconnect, connect } = useFclReact()
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<AppDispatch>()

  const clearAllTransactionsCallback = useCallback(() => {
    if (!chainId) return
    dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  const reconnect = () => {
    toggleWalletModal()
    disconnect()
    connect()
  }

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>Account</HeaderRow>

        <AccountSection>
          <InfoCard>
            <IconWrapper size={40}>
              <img src={flowIcon} alt={'flow logo'} />
            </IconWrapper>
            <AccountControl>
              <WalletAddress href={getFvsLink(chainId, account, 'account')} target="_blank">
                {account}
              </WalletAddress>
              <WalletName>
                Blocto
                {account && (
                  <Copy toCopy={account}>
                    <span style={{ marginLeft: '4px' }}>Copy Address</span>
                  </Copy>
                )}
              </WalletName>
            </AccountControl>
            <WalletAction onClick={reconnect}>Change</WalletAction>
          </InfoCard>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.small color={theme.text3} style={{ textTransform: 'uppercase' }}>
              Recent Transactions
            </TYPE.small>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>CLEAR</LinkStyledButton>
          </AutoRow>
          <Separator />
          <TransactionList>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </TransactionList>
        </LowerSection>
      ) : (
        <LowerSection>
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.body fontSize={14} color={theme.text1}>
              Your transactions will appear here...
            </TYPE.body>
          </AutoRow>
        </LowerSection>
      )}
    </>
  )
}
