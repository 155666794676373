import React, { useContext } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { TYPE } from '../../theme'
import { ExternalLink } from '../../theme/components'
import { getFvsLink, getBSCscanLink, getEtherscanLink, getSolanaExplorerLink } from '../../utils'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { Network } from '../../types'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function TransactionPopup({
  hash,
  success,
  summary,
  network
}: {
  hash: string
  success?: boolean
  summary?: string
  network: string
}) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const getLink = () => {
    switch (network) {
      default:
      case Network.FLOW:
        return { link: getFvsLink(chainId, hash, 'transaction'), text: 'View on Flowscan' }
      case Network.ETHEREUM:
        return { link: getEtherscanLink(chainId, hash, 'transaction'), text: 'View on Etherscan' }
      case Network.BSC:
        return { link: getBSCscanLink(chainId, hash, 'transaction'), text: 'View on BscScan' }
      case Network.SOLANA:
        return {
          link: getSolanaExplorerLink(chainId, hash, 'tx'),
          text: 'View on Solana Explorer'
        }
    }
  }

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? <CheckCircle color={theme.green1} size={24} /> : <AlertCircle color={theme.red1} size={24} />}
      </div>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</TYPE.body>
        {chainId && <ExternalLink href={getLink().link}>{getLink().text}</ExternalLink>}
      </AutoColumn>
    </RowNoFlex>
  )
}
