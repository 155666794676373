import { useMemo, useState, useEffect, useContext } from 'react'
import { useFclReact } from './useFclReact'
import { FLOW_TOKEN, TUSDT, FLOW_TUSDT_LP, FUSD, BLT, BLT_SOLANA, BLT_TUSDT_LP } from '../constants'
import getBalances from './scripts/getBalances'
import { replaceContractAddresses } from './env'
import { SolanaContext } from '../solana-react/SolanaContext'
import { useSolana } from '../solana-react/useSolana'

export function useTokenBalances(
  address: string | undefined,
  nonce?: number | undefined
): { [tokenAddress: string]: number | undefined } {
  const { fcl, types, chainId } = useFclReact()
  const [balances, setBalances] = useState<{ [tokenAddress: string]: number | undefined }>({})
  const { solana } = useContext<any>(SolanaContext)
  const solAccount = useSolana().account

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!address || !fcl) {
      setBalances({
        [FLOW_TOKEN[chainId]?.address ?? 'FLOW_TOKEN']: undefined,
        [TUSDT[chainId]?.address ?? 'TUSDT']: undefined,
        [FUSD[chainId]?.address ?? 'FUSD']: undefined,
        [BLT[chainId]?.address ?? 'BLT']: undefined,
        [FLOW_TUSDT_LP[chainId]?.address ?? 'FLOW_TUSDT_LP']: undefined,
        [BLT_TUSDT_LP[chainId]?.address ?? 'BLT_TUSDT_LP']: undefined
      })

      return callback
    }

    const script = replaceContractAddresses(getBalances, chainId)

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(address, types.Address)])])
      .then(fcl.decode)
      .then((results: [string, string, string, string, string, string]) => {
        isSubscribed &&
          setBalances(prev => {
            return {
              ...prev,
              [FLOW_TOKEN[chainId]?.address ?? 'FLOW_TOKEN']: parseFloat(results[0]),
              [TUSDT[chainId]?.address ?? 'TUSDT']: parseFloat(results[1]),
              [FUSD[chainId]?.address ?? 'FUSD']: parseFloat(results[2]),
              [BLT[chainId]?.address ?? 'BLT']: parseFloat(results[3]),
              [FLOW_TUSDT_LP[chainId]?.address ?? 'FLOW_TUSDT_LP']: parseFloat(results[4]),
              [BLT_TUSDT_LP[chainId]?.address ?? 'BLT_TUSDT_LP']: parseFloat(results[5])
            }
          })
      })
      .catch((error: Error) => {
        console.log(error)
      })

    solana &&
      solana
        .request({
          method: 'getTokenAccountsByOwner',
          params: [
            solAccount,
            {
              mint: BLT_SOLANA[chainId].address
            },
            {
              encoding: 'jsonParsed'
            }
          ]
        })
        .then((res: any) => {
          const bal = res?.value[0]?.account?.data.parsed.info.tokenAmount.uiAmount || 0
          setBalances(prev => {
            return { ...prev, [BLT_SOLANA[chainId].address]: bal }
          })
        })

    return callback
  }, [address, fcl, types, nonce, chainId, solAccount, solana])

  return useMemo(() => balances, [balances])
}
