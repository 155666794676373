import { BigNumber } from '@ethersproject/bignumber'
import { useMemo } from 'react'
import { Trade, useFclReact } from '../fcl-react'
import { useTransactionAdder as useEthTransactionAdder } from '../state/transactionsEthereum/hooks'
import { useTransactionAdder as useBscTransactionAdder } from '../state/transactionsBsc/hooks'
import { useTeleportContract } from '../hooks/useContract'
import { calculateGasMargin } from '../utils'
import { Network, TransactionResponse } from '../types'
import { useActiveWeb3React } from './index'

export enum TeleportCallbackState {
  INVALID,
  LOADING,
  VALID
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useTeleportCallback(
  trade: Trade | undefined // trade to execute, required
): { state: TeleportCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account: ethAccount, chainId, library } = useActiveWeb3React()
  const { account: flowAccount } = useFclReact()

  const teleportContract = useTeleportContract()

  const addEthTransaction = useEthTransactionAdder()
  const addBscTransaction = useBscTransactionAdder()

  return useMemo(() => {
    if (!trade || !library || !ethAccount || !flowAccount || !chainId || !teleportContract) {
      return { state: TeleportCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }

    const isBSC = (chainId as number) === 56 || (chainId as number) === 97
    const inputNetwork = trade.inputCurrency.network
    // add this check to avoid network not matched and teleport into eth address
    const bscNetworkMatched = (isBSC && inputNetwork === Network.BSC) || (!isBSC && inputNetwork !== Network.BSC)

    if (!bscNetworkMatched) {
      return { state: TeleportCallbackState.INVALID, callback: null, error: 'Nework Error' }
    }

    const formattedNumber = BigNumber.from(trade.inputAmount.toFixed(trade.inputCurrency.decimals).replace('.', ''))
    const args: any[] = [formattedNumber.toHexString(), flowAccount]
    const options = {
      from: ethAccount
    }

    const teleportERC20USDT = () => {
      return teleportContract.estimateGas['lock'](...args).then(gasEstimate =>
        teleportContract.lock(...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...options
        })
      )
    }

    const teleportBEP20BLT = () => {
      return teleportContract.estimateGas['teleportIn'](...args).then(gasEstimate =>
        teleportContract.teleportIn(...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...options
        })
      )
    }

    return {
      state: TeleportCallbackState.VALID,
      callback: function onTeleport(): Promise<string> {
        const teleport = isBSC ? teleportBEP20BLT : teleportERC20USDT
        return teleport()
          .then((response: TransactionResponse) => {
            const inputSymbol = trade.inputCurrency.symbol
            const inputAmount = trade.inputAmount.toFixed(4)

            const CHAIN_NAME = {
              FLOW: 'Flow',
              BSC: 'Binance Smart Chain',
              ETHEREUM: 'Ethereum',
              SOLANA: 'Solana'
            }

            const inputChain = CHAIN_NAME[trade.inputCurrency.network]
            const outputChain = CHAIN_NAME[trade.outputCurrency.network]

            const summary = `Teleport ${inputAmount} ${inputSymbol} from ${inputChain} to ${outputChain}`

            const addTransaction = isBSC ? addBscTransaction : addEthTransaction
            addTransaction(response, {
              summary
            })

            return response.hash || ''
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Teleport failed`, error)
              throw new Error(`Teleport failed: ${error.message}`)
            }
          })
      },
      error: null
    }
  }, [trade, library, flowAccount, ethAccount, chainId, addEthTransaction, addBscTransaction, teleportContract])
}
