// // Wrong vault type, destroy it
// destroy signer.load<@AnyResource>(from: TeleportedTetherToken.TokenStoragePath)
// signer.unlink(TeleportedTetherToken.TokenPublicReceiverPath)
// signer.unlink(TeleportedTetherToken.TokenPublicBalancePath)

const scriptEnableUSDT = `\
// Add a Vault resource to user's account
// so that they can use the teleportedTetherToken (USDT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction {

  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Get a reference to the proxyHolder's stored vault
      let proxyHolderVaultRef = proxyHolder.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
			  ?? panic("Could not borrow reference to the proxyHolder's Vault!")

      // Get a reference to the signer's stored vault
      let signerVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
			  ?? panic("Could not borrow reference to the signer's Vault!")

      // Transfer 0.0001 FLOW to signer
      signerVaultRef.deposit(from: <- proxyHolderVaultRef.withdraw(amount: 0.0001))

      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }
  }
}
`

const scriptEnableFUSD = `\
// This transaction is a template for a transaction
// to add a Vault resource to their account
// so that they can use the FUSD

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS

transaction {

  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) != nil) {
        return
    }

    // Get a reference to the proxyHolder's stored vault
    let proxyHolderVaultRef = proxyHolder.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow reference to the proxyHolder's Vault!")

    // Get a reference to the signer's stored vault
    let signerVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow reference to the signer's Vault!")

    // Transfer 0.0001 FLOW to signer
    signerVaultRef.deposit(from: <- proxyHolderVaultRef.withdraw(amount: 0.0001))
    
    // Create a new FUSD Vault and put it in storage
    signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
    )

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
    )
  }
}
`

const scriptEnableBLT = `\
// Add a Vault resource to user's account
// so that they can use the teleportedTetherToken (USDT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS

transaction {

  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Get a reference to the proxyHolder's stored vault
      let proxyHolderVaultRef = proxyHolder.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
			  ?? panic("Could not borrow reference to the proxyHolder's Vault!")

      // Get a reference to the signer's stored vault
      let signerVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
			  ?? panic("Could not borrow reference to the signer's Vault!")

      // Transfer 0.0001 FLOW to signer
      signerVaultRef.deposit(from: <- proxyHolderVaultRef.withdraw(amount: 0.0001))

      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }
  }
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptEnableUSDT,
  FUSD: scriptEnableFUSD,
  BLT: scriptEnableBLT
}

export default scripts
