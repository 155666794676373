// @param swapPairs - depedency swap pairs
// to make the swap pair works, all depedency swap pairs should not be frozen
// for example: Flow <-> FUSD requires Flow <-> tUSDT and tUSDT -> FUSD pairs,
// then all these pairs should be available for Flow <-> FUSD to work
const makeFrozenScript = (
  swapPairs: Array<{
    name: string
    address: string
  }>
) => {
  const importSnippet = swapPairs.map(({ name, address }) => `import ${name} from ${address}`).join('\n')
  const pairsFrozenSnippet = swapPairs.map(({ name }) => `${name}.isFrozen`).join(' || ')

  return `\
${importSnippet}

pub fun main(): Bool {
  return ${pairsFrozenSnippet}
}
`
}

const scriptFlowUsdt = makeFrozenScript([{ name: 'FlowSwapPair', address: '0xFLOWSWAPPAIRADDRESS' }])

const scriptFlowBlt = makeFrozenScript([
  { name: 'FlowSwapPair', address: '0xFLOWSWAPPAIRADDRESS' },
  { name: 'BltUsdtSwapPair', address: '0xBLTUSDTSWAPPAIRADDRESS' }
])

const scriptFusdUsdt = makeFrozenScript([{ name: 'FusdUsdtSwapPair', address: '0xFUSDUSDTSWAPPAIRADDRESS' }])
const scriptFusdBlt = makeFrozenScript([
  { name: 'FusdUsdtSwapPair', address: '0xFUSDUSDTSWAPPAIRADDRESS' },
  { name: 'BltUsdtSwapPair', address: '0xBLTUSDTSWAPPAIRADDRESS' }
])

const scriptBltUsdt = makeFrozenScript([{ name: 'BltUsdtSwapPair', address: '0xBLTUSDTSWAPPAIRADDRESS' }])

const scriptFlowFusd = makeFrozenScript([
  { name: 'FlowSwapPair', address: '0xFLOWSWAPPAIRADDRESS' },
  { name: 'FusdUsdtSwapPair', address: '0xFUSDUSDTSWAPPAIRADDRESS' }
])

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptFlowUsdt,
    FUSD: scriptFlowFusd,
    BLT: scriptFlowBlt
  },
  tUSDT: {
    FLOW: scriptFlowUsdt,
    FUSD: scriptFusdUsdt,
    BLT: scriptBltUsdt
  },
  FUSD: {
    tUSDT: scriptFusdUsdt,
    FLOW: scriptFlowFusd,
    BLT: scriptFusdBlt
  },
  BLT: {
    tUSDT: scriptBltUsdt,
    FUSD: scriptFusdBlt,
    FLOW: scriptFlowBlt
  }
}

export default scripts
