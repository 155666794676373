import { ChainId, JSBI, Percent } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { TokenInfo, Network } from '../types'
import { fortmatic, injected, portis, walletconnect, walletlink, bloctosdk, bloctosdkBSC } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const PRODUCTION_URL = 'https://swap.blocto.app'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: TokenInfo[]
}

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 14
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const TELEPORT_ADDRESS: { [key: number]: string } = {
  [ChainId.MAINNET]: '0xf8F12fE1B51D1398019C4faCd4D00aDAb5fEF746',
  [ChainId.RINKEBY]: '0xFfB71C8c8f309e86F527CD64db9a164f7d2a5a7b',
  [ChainId.ROPSTEN]: '0xFfB71C8c8f309e86F527CD64db9a164f7d2a5a7b',
  [ChainId.GÖRLI]: '0xFfB71C8c8f309e86F527CD64db9a164f7d2a5a7b',
  [ChainId.KOVAN]: '0xFfB71C8c8f309e86F527CD64db9a164f7d2a5a7b',
  56: '0x567f7048785fcEF7944B1C980AcbB32d0CA573B7',
  97: '0x5995e834B620544818268c31d1781B3E8953fBaf'
}

export const FLOW_TOKEN: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x1654653399040a61',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW'
  }
}

export const TUSDT: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xcfdd90d4a00f7b5b',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT'
  }
}

export const FUSD: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x3c5959b568896393',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD'
  }
}

export const BLT: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x0f9df91c9121c460',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  }
}

export const BLT_SOLANA: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.SOLANA,
    chainId: ChainId.MAINNET,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  }
}

export const BLT_BSC: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.BSC,
    chainId: ChainId.MAINNET,
    address: '0x02Bdf640fba368E7Ba5c6429cCaF251512273865',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT'
  }
}

export const USDT: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT'
  }
}

export const FLOW_TUSDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xc6c77b9f5c7a378f',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  }
}

export const BLT_TUSDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xfcb06a5ae5b21a2d',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xc59604d4e65f14b3',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.MAINNET]: [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
  [ChainId.RINKEBY]: [FLOW_TOKEN[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.KOVAN]: []
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
  [ChainId.RINKEBY]: [FLOW_TOKEN[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.KOVAN]: []
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [TokenInfo, TokenInfo][] } = {
  [ChainId.MAINNET]: []
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  BLOCTOSDK: {
    connector: bloctosdk,
    name: 'Blocto',
    iconName: 'blocto.svg',
    description: 'Easiest mobile crypto wallet',
    href: null,
    color: '#1336BF',
    mobile: true
  },
  BLOCTOSDK_BSC: {
    connector: bloctosdkBSC,
    name: 'Blocto',
    iconName: 'blocto.svg',
    description: 'Easiest mobile crypto wallet',
    href: null,
    color: '#1336BF',
    mobile: true
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 100
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = 0.0001
export const BIPS_BASE = 10000
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW = 1.0 // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = 3.0 // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = 5.0 // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = 10.0 // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = 50.0 // 50%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
