import React from 'react'
import styled from 'styled-components'
import { CheckCircle, AlertTriangle } from 'react-feather'

import { useActiveWeb3React } from '../../hooks'
import { getBSCscanLink, getEtherscanLink, getFvsLink, getSolanaExplorerLink } from '../../utils'
import { TransactionAbstract, Network } from '../../types'
import { ExternalLink } from '../../theme'
import { useAllTransactions as useAllEthTransactions } from '../../state/transactionsEthereum/hooks'
import { useAllTransactions as useAllBscTransactions } from '../../state/transactionsBsc/hooks'
import { useAllTransactions as useAllFlowTransactions } from '../../state/transactionsFlow/hooks'
import { useAllTransactions as useAllSolanaTransactions } from '../../state/transactionsSolana/hooks'
import Loader from '../Loader'
import NetworkLogo from '../NetworkLogo'

const TransactionWrapper = styled.div``

const TransactionStatusText = styled.div`
  flex: 1;
  margin: 0 0.5rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.5rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
  overflow: hidden;
`

export default function Transaction({ transaction }: { transaction: TransactionAbstract }) {
  const { chainId } = useActiveWeb3React()
  const allEthTransactions = useAllEthTransactions()
  const allBscTransactions = useAllBscTransactions()
  const allFlowTransactions = useAllFlowTransactions()
  const allSolanaTransactions = useAllSolanaTransactions()
  const { hash, network } = transaction

  let tx: any,
    link: any,
    success = false,
    pending = false,
    summary: any

  switch (network) {
    case Network.FLOW:
      tx = allFlowTransactions?.[hash]
      link = getFvsLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = !pending && tx && tx.receipt?.statusCode === 0
      break
    case Network.ETHEREUM:
      tx = allEthTransactions?.[hash]
      link = getEtherscanLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = (!pending && tx && tx.receipt?.status === 1) || typeof tx.receipt?.status === 'undefined'
      break
    case Network.BSC:
      tx = allBscTransactions?.[hash]
      link = getBSCscanLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = (!pending && tx && tx.receipt?.status === 1) || typeof tx.receipt?.status === 'undefined'
      break
    case Network.SOLANA:
      tx = allSolanaTransactions?.[hash]
      link = getSolanaExplorerLink(chainId, hash, 'tx')
      pending = !tx?.receipt
      summary = tx?.summary
      success = !pending && tx && tx.receipt?.statusCode === 0
      break
  }

  if (!chainId) return null

  return (
    <TransactionWrapper>
      <TransactionState href={link} pending={pending} success={success}>
        <NetworkLogo network={network} size={24} />
        <TransactionStatusText>{summary ?? hash} ↗</TransactionStatusText>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size="16" /> : <AlertTriangle size="16" />}
        </IconWrapper>
      </TransactionState>
    </TransactionWrapper>
  )
}
