import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { Trade } from '../../fcl-react'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import { ErrorText } from './styleds'

function TradeSummary({ trade }: { trade: Trade }) {
  const theme = useContext(ThemeContext)

  return (
    <>
      <AutoColumn style={{ padding: '0 20px' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Teleport Fee
            </TYPE.black>
            <QuestionHelper text="A fixed amount is charged in each teleport to cover the cost of teleport service and network transaction fee." />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {trade.fee ? `${trade.fee.toFixed(3)} ${trade.inputCurrency.symbol.replace('t', '')}` : '-'}
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Process Time
            </TYPE.black>
            <QuestionHelper text="Teleporting more than 1000 USDT requires manual auditing and can take up to 24 hours." />
          </RowFixed>
          <ErrorText fontWeight={500} fontSize={14} severity={trade.inputAmount >= 100000 ? 2 : 0}>
            {trade.inputAmount >= 100000 ? '<24 hours' : '~15 minutes'}
          </ErrorText>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedTeleportDetailsProps {
  trade?: Trade
}

export function AdvancedTeleportDetails({ trade }: AdvancedTeleportDetailsProps) {
  return <AutoColumn gap="md">{trade && <TradeSummary trade={trade} />}</AutoColumn>
}
