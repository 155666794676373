import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { darken } from 'polished'
import React, { useMemo } from 'react'
import { Users } from 'react-feather'
import { Activity } from 'react-feather'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChainId } from '@uniswap/sdk'
import { useFclReact } from '../../fcl-react'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useWalletModalMixedToggle } from '../../state/application/hooks'
import { useAllTransactions as useAllEthTransactions } from '../../state/transactionsEthereum/hooks'
import { useAllTransactions as useAllBscTransactions } from '../../state/transactionsBsc/hooks'
import { isTransactionRecent, useAllTransactions as useAllFlowTransactions } from '../../state/transactionsFlow/hooks'
import { useAllTransactions as useAllSolanaTransactions } from '../../state/transactionsSolana/hooks'
import { shortenEthereumAddress, shortenAddress, shortenENSName, shortenSolanaAddress } from '../../utils'
import { ButtonSecondary, ButtonWallet } from '../Button'
import { TransactionAbstract, Network } from '../../types'
import Loader from '../Loader'
import NetworkLogo from '../NetworkLogo'
import { useSolana } from '../../solana-react/useSolana'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModalMixed'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  user-select: none;
  text-transform: none;
  height: 35px;

  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;

  :hover,
  :focus {
    border: none;
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.primary1) : darken(0.05, theme.bg3))};
  }
`

const Text = styled.span`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.5rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const Separator = styled.div`
  width: 1px;
  height: 1rem;
  margin: 0 0.75rem;
  background-color: ${({ theme }) => theme.text4};
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionAbstract, b: TransactionAbstract) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({ pendingTransactions }: { pendingTransactions: TransactionAbstract[] }) {
  const { t } = useTranslation()
  const { chainId, account: ethAccount, error } = useWeb3React()
  const { account: flowAccount } = useFclReact()
  const { account: solanaAccount } = useSolana()

  const { ENSName } = useENSName(ethAccount ?? undefined)

  const hasPendingTransactions = !!pendingTransactions.length
  const toggleWalletModal = useWalletModalMixedToggle()

  const isETH = chainId === ChainId.MAINNET || chainId === ChainId.RINKEBY

  const smallScreen = useMemo(() => {
    return window.innerWidth < 560
  }, [])

  if (ethAccount || flowAccount || solanaAccount) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Loader stroke="white" />
            <Text>{pendingTransactions?.length} Pending</Text>
          </RowBetween>
        ) : (
          <>
            {flowAccount && (
              <>
                <NetworkLogo network={'FLOW'} size={22} />
                <Text>{shortenAddress(flowAccount, smallScreen ? 1 : 3)}</Text>
              </>
            )}
            {ethAccount && (
              <>
                {flowAccount ? <Separator /> : null}
                <NetworkLogo network={isETH ? 'ETHEREUM' : 'BSC'} size={22} />
                <Text>{shortenENSName(ENSName) || shortenEthereumAddress(ethAccount, smallScreen ? 1 : 3)}</Text>
              </>
            )}
            {solanaAccount ? (
              <>
                {flowAccount || ethAccount ? <Separator /> : null}
                <NetworkLogo network={'SOLANA'} size={22} />
                <Text>{shortenSolanaAddress(solanaAccount, smallScreen ? 2 : 4)}</Text>
              </>
            ) : null}
          </>
        )}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <ButtonWallet id="connect-wallet" onClick={toggleWalletModal}>
        <Users size={18} style={{ marginRight: '10px' }} />
        {t('connectWallets')}
      </ButtonWallet>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allEthTransactions = useAllEthTransactions()
  const allBscTransactions = useAllBscTransactions()
  const allFlowTransactions = useAllFlowTransactions()
  const allSolanaTransactions = useAllSolanaTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const ethTxs = Object.values(allEthTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.ETHEREUM
    }))
    const bscTxs = Object.values(allBscTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.BSC
    }))
    const flowTxs = Object.values(allFlowTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.FLOW
    }))
    const solTxs = Object.values(allSolanaTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.SOLANA
    }))
    return ethTxs
      .concat(bscTxs)
      .concat(flowTxs)
      .concat(solTxs)
      .filter(isTransactionRecent)
      .sort(newTransactionsFirst)
  }, [allEthTransactions, allBscTransactions, allFlowTransactions, allSolanaTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner pendingTransactions={pending} />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
