import React from 'react'
import styled from 'styled-components'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedTeleportDetails, AdvancedTeleportDetailsProps } from './AdvancedTeleportDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(20px + 2.5rem);
  padding-bottom: ${({ show }) => (show ? 20 : 0)}px;
  margin-top: -2.5rem;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => theme.advancedBG};
  z-index: -1;

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

export default function AdvancedTeleportDetailsDropdown({ trade, ...rest }: AdvancedTeleportDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter show={Boolean(trade) && trade?.inputAmount !== Number.MAX_VALUE}>
      <AdvancedTeleportDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
