let eventTarget = 'https://flow-wallet.blocto.app/authn'
let timer = null

export const passOverAccessToken = (token, target) => {
  const frame = document.getElementById('FCL_IFRAME')
  const accessToken = token || window.accessToken

  if (frame && accessToken) {
    frame.contentWindow.postMessage(
      {
        action: 'bloctoAccessToken',
        accessToken
      },
      target
    )
  } else {
    // eslint-disable-next-line
    initiatePassOver(accessToken, target)
  }
}

export const initiatePassOver = (accessToken, target) => {
  if (timer) {
    clearInterval(timer)
  }

  timer = setInterval(() => passOverAccessToken(accessToken, target), 200)
}

const processPassOver = ({ detail }) => {
  passOverAccessToken(detail, eventTarget)
}

export const init = () => {
  window.addEventListener('bloctoAccessToken', processPassOver)

  window.addEventListener('message', event => {
    if (
      event.origin.startsWith('https://flow-wallet.blocto.app') ||
      event.origin.startsWith('https://flow-wallet-testnet.blocto.app')
    ) {
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
      switch (event.data) {
        case 'bloctoAccessTokenReceived':
          if (timer) {
            clearInterval(timer)
            timer = null
          }
          break

        default:
      }
    }
  })
}

export const subscribeAccessToken = target => {
  eventTarget = target
}
