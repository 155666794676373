import { ChainId, TradeType } from '@uniswap/sdk'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Network, TokenInfo } from '../../types'
import { USDT, TUSDT } from '../../constants'
import { useFclReact, Trade, useTeleportFees } from '../../fcl-react'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import useDebounce from '../../hooks/useDebounce'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isFlowAddress } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useCurrencyBalances } from '../wallet/ethereumHooks'
import { useTokenBalances } from '../wallet/flowHooks'
import { useBLTSOLBalance } from '../../solana-react/useSolana'
import { Field, replaceTeleportState, selectCurrency, switchCurrencies, typeInput } from './actions'
import { TeleportState } from './reducer'

export function useTeleportState(): AppState['teleport'] {
  return useSelector<AppState, AppState['teleport']>(state => state.teleport)
}

export function useTeleportActionHandlers(): {
  onCurrencySelection: (field: Field, currency: TokenInfo) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: TokenInfo) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency?.address
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: TokenInfo): number | undefined {
  if (!value || !currency) {
    return undefined
  }

  return parseFloat(value)
}

// from the current teleport inputs, compute the best trade and return it.
export function useDerivedTeleportInfo(): {
  currencies: { [field in Field]?: TokenInfo }
  currencyBalances: { [field in Field]?: number }
  parsedAmount: number | undefined
  trade: Trade | undefined
  inputError?: string
} {
  const { account: flowAccount } = useFclReact()
  const { account: ethAccount } = useActiveWeb3React()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId }
  } = useTeleportState()

  const debouncedTypedValue = useDebounce(typedValue, 250)

  const inputCurrency = useCurrency(inputCurrencyId) || undefined
  const outputCurrency = useCurrency(outputCurrencyId) || undefined

  const inputNetwork = inputCurrency?.network
  const outputNetwork = outputCurrency?.network

  const relevantFlowTokenBalances = useTokenBalances(flowAccount ?? undefined)
  const relevantEthTokenBalances = useCurrencyBalances(ethAccount ?? undefined, [
    inputNetwork === Network.ETHEREUM || inputNetwork === Network.BSC ? inputCurrency : undefined,
    outputNetwork === Network.ETHEREUM || outputNetwork === Network.BSC ? outputCurrency : undefined
  ])
  const relevantBLTSOLBalance = useBLTSOLBalance(
    null,
    inputCurrency?.network === Network.SOLANA ? inputCurrency : outputCurrency
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(debouncedTypedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

  // Calculate fees
  const fees = useTeleportFees(inputCurrency ?? undefined, outputCurrency ?? undefined)
  const fee = inputCurrency?.network === Network.FLOW ? fees?.outwardFee : fees?.inwardFee

  const trade: Trade | undefined =
    inputCurrency && outputCurrency && fee?.toString() && parsedAmount
      ? {
          tradeType: isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
          inputCurrency,
          outputCurrency,
          route: [],
          inputAmount: isExactIn ? parsedAmount : parsedAmount + fee,
          outputAmount: isExactIn ? (parsedAmount > fee ? parsedAmount - fee : 0) : parsedAmount,
          executionPrice: 1,
          priceImpact: 0.000001,
          fee
        }
      : undefined

  const switchCurrencyBalance = (field: string) => {
    switch (inputCurrency?.network) {
      case Network.FLOW:
        return relevantFlowTokenBalances[inputCurrency?.address ?? '']
      case Network.ETHEREUM:
      case Network.BSC:
        return field === Field.INPUT ? relevantEthTokenBalances[0] : relevantEthTokenBalances[1]
      case Network.SOLANA:
        return relevantBLTSOLBalance
      default:
        return undefined
    }
  }

  const currencyBalances = {
    [Field.INPUT]: switchCurrencyBalance(Field.INPUT),
    [Field.OUTPUT]: switchCurrencyBalance(Field.OUTPUT)
  }

  const currencies: { [field in Field]?: TokenInfo } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  let inputError: string | undefined

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  if (isExactIn && parsedAmount && fee && parsedAmount <= fee) {
    inputError = inputError ?? 'Amount too small'
  }

  const amountIn = isExactIn ? parsedAmount || 0 : (parsedAmount || 0) + (fee || 0)

  if ((currencyBalances[Field.INPUT] ?? 0) < amountIn) {
    inputError = 'Insufficient ' + inputCurrency?.symbol + ' balance'
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    trade,
    inputError
  }
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isFlowAddress(urlParam)
    return valid ? valid : USDT[ChainId.MAINNET].address
  }
  return USDT[ChainId.MAINNET].address
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

export function queryParametersToTeleportState(parsedQs: ParsedQs): TeleportState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  if (inputCurrency === outputCurrency) {
    if (outputCurrency === USDT[ChainId.MAINNET].address) {
      // both currencies are FLOW
      inputCurrency = TUSDT[ChainId.MAINNET].address
    } else if (typeof parsedQs.outputCurrency === 'string') {
      // user specified output currency
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField)
  }
}

// updates the teleport state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useFclReact()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!chainId) return
    const parsed = queryParametersToTeleportState(parsedQs)

    dispatch(
      replaceTeleportState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId
      })
    )

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}
