import { useMemo } from 'react'
import { Trade } from './types'
import { useFclReact } from './useFclReact'
import { INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'
import swapExactIn from './transactions/swapExactIn'
import swapExactOut from './transactions/swapExactOut'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { Field } from '../state/swap/actions'
import { TransactionResponse } from '../types'
import { TradeType } from '@uniswap/sdk'
import { replaceContractAddresses } from './env'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE // in bips
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, fcl, authorization, types, chainId } = useFclReact()

  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!trade || !account || !trade?.inputCurrency?.symbol || !trade?.outputCurrency?.symbol) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }

    const isExactIn = trade.tradeType === TradeType.EXACT_INPUT

    let script = isExactIn
      ? swapExactIn[trade?.inputCurrency?.symbol ?? '']?.[trade?.outputCurrency?.symbol ?? ''] ?? ''
      : swapExactOut[trade?.inputCurrency?.symbol ?? '']?.[trade?.outputCurrency?.symbol ?? ''] ?? ''
    script = replaceContractAddresses(script, chainId)

    if (!script) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Cannot find swap sciprt' }
    }

    const slippage = trade && allowedSlippage && computeSlippageAdjustedAmounts(trade, allowedSlippage)
    const maxAmountIn = (slippage && slippage[Field.INPUT]) || trade.inputAmount
    const minAmountOut = (slippage && slippage[Field.OUTPUT]) || trade.outputAmount
    const formattedInput = isExactIn ? trade.inputAmount.toFixed(8) : maxAmountIn.toFixed(8)
    const formattedOutput = isExactIn ? minAmountOut.toFixed(8) : trade.outputAmount.toFixed(8)

    const isSealed = false

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        return fcl
          .send([fcl.getBlock(isSealed)])
          .then(fcl.decode)
          .then((block: any) =>
            fcl.send([
              fcl.transaction(script),
              fcl.args([fcl.arg(formattedInput, types.UFix64), fcl.arg(formattedOutput, types.UFix64)]),
              fcl.limit(300),
              fcl.proposer(authorization),
              fcl.authorizations([authorization]),
              fcl.payer(authorization),
              fcl.ref(block.id)
            ])
          )
          .then((response: TransactionResponse) => {
            const inputSymbol = trade.inputCurrency.symbol
            const outputSymbol = trade.outputCurrency.symbol
            const inputAmount = trade.inputAmount.toFixed(4)
            const outputAmount = trade.outputAmount.toFixed(4)

            const summary = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`

            addTransaction(response, {
              summary
            })

            return response.transactionId
          })
          .catch((error: Error) => {
            // if the user rejected the tx, pass this along
            if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, script)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null
    }
  }, [trade, account, allowedSlippage, addTransaction, authorization, fcl, types, chainId])
}
