import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@uniswap/sdk'
import { TransactionReceipt } from '../../types'

export const addTransaction = createAction<{
  chainId: ChainId
  hash: string
  from: string
  approval?: { tokenAddress: string }
  claim?: { recipient: string }
  summary?: string
  teleport?: { id: string }
}>('transactionsFlow/addTransaction')
export const clearAllTransactions = createAction<{ chainId: ChainId }>('transactionsFlow/clearAllTransactions')
export const finalizeTransaction = createAction<{
  chainId: ChainId
  hash: string
  receipt: TransactionReceipt
}>('transactionsFlow/finalizeTransaction')
export const checkedTransaction = createAction<{
  chainId: ChainId
  hash: string
  nonce: number
}>('transactionsFlow/checkedTransaction')
