import {
  LOQUIDITY_TOKEN_LIST,
  TOKEN_LIST,
  TELEPORT_TOKEN_LIST,
  TELEPORT_TARGET,
  TokenAddressMap,
  TeleportTargetMap
} from '../../constants/lists'

export function useSwapTokenList(showLiquidityTokens = false): TokenAddressMap {
  return showLiquidityTokens ? LOQUIDITY_TOKEN_LIST : TOKEN_LIST
}

export function useTeleportTokenList(): TokenAddressMap {
  return TELEPORT_TOKEN_LIST
}

export function useTeleportTargetList(): TeleportTargetMap {
  return TELEPORT_TARGET
}
