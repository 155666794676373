const scriptFlowUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return FlowSwapPair.totalSupply
}
`

const scriptFusdUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return FusdUsdtSwapPair.totalSupply
}
`

const scriptBltUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return BltUsdtSwapPair.totalSupply
}
`

const scripts: { [tokenName: string]: string } = {
  'FLOW:tUSDT': scriptFlowUsdt,
  'FUSD:tUSDT': scriptFusdUsdt,
  'BLT:tUSDT': scriptBltUsdt
}

export default scripts
