const script = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS 
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FUSD from 0xFUSDADDRESS
import BloctoToken from 0xBLTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

// This script reads the Vault balances
pub fun main(address: Address): [UFix64] {
    // Get the accounts' public account objects
    let account = getAccount(address)

    // Get references to the account's receivers
    // by getting their public capability
    // and borrowing a reference from the capability
    let flowBalanceRef = account.getCapability(/public/flowTokenBalance)
        .borrow<&FlowToken.Vault{FungibleToken.Balance}>()

    let usdtBalanceRef = account.getCapability(TeleportedTetherToken.TokenPublicBalancePath)
        .borrow<&TeleportedTetherToken.Vault{FungibleToken.Balance}>()
    
    let fusdBalanceRef = account.getCapability(/public/fusdBalance)
        .borrow<&FUSD.Vault{FungibleToken.Balance}>()

    let bltBalanceRef = account.getCapability(BloctoToken.TokenPublicBalancePath)
        .borrow<&BloctoToken.Vault{FungibleToken.Balance}>()

    let fspBalanceRef = account.getCapability(FlowSwapPair.TokenPublicBalancePath)
        .borrow<&FlowSwapPair.Vault{FungibleToken.Balance}>()

    let bltUsdtSwapBalanceRef = account.getCapability(BltUsdtSwapPair.TokenPublicBalancePath)
        .borrow<&BltUsdtSwapPair.Vault{FungibleToken.Balance}>()

    let flowBalance = flowBalanceRef == nil ? 0.0 : flowBalanceRef!.balance
    let usdtBalance = usdtBalanceRef == nil ? 0.0 : usdtBalanceRef!.balance
    let fusdBalance = fusdBalanceRef == nil ? 0.0 : fusdBalanceRef!.balance
    let bltBalance = bltBalanceRef == nil ? 0.0 : bltBalanceRef!.balance
    let fspBalance = fspBalanceRef == nil ? 0.0 : fspBalanceRef!.balance
    let bltUsdtSwapBalance = bltUsdtSwapBalanceRef == nil ? 0.0 : bltUsdtSwapBalanceRef!.balance

    return [flowBalance, usdtBalance, fusdBalance, bltBalance, fspBalance, bltUsdtSwapBalance]
}
`

export default script
