import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { ChainId, Token, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../constants/lists'

// returns the checksummed address if the address is valid, otherwise returns false
export function isFlowAddress(value: any): string | false {
  return /^0x[\da-f]{16}$/.test(value) ? value : false
}

export const isAddress = isFlowAddress

export function isEthereumAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.'
}

const BSCSCAN_PREFIXES: { [key: number]: string } = {
  56: '',
  97: 'testnet.'
}

const FVS_PREFIXES: { [key: number]: string } = {
  1: 'mainnet',
  3: 'testnet',
  4: 'testnet',
  5: 'testnet',
  42: 'testnet',
  56: 'mainnet',
  97: 'testnet'
}

export function getEtherscanLink(
  chainId?: ChainId,
  data?: string,
  type?: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId ?? ChainId.MAINNET] || ''}etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function getBSCscanLink(
  chainId?: number,
  data?: string,
  type?: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${BSCSCAN_PREFIXES[chainId ?? 56] || ''}bscscan.com`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function getFvsLink(
  chainId: ChainId | undefined,
  data: string | undefined,
  type: 'transaction' | 'token' | 'account' | 'block'
): string {
  const prefix =
    chainId === ChainId.MAINNET || Number(chainId) === 56
      ? 'https://flowscan.org'
      : `https://flow-view-source.com/${FVS_PREFIXES[chainId ?? ChainId.MAINNET]}`

  switch (type) {
    case 'transaction': {
      return chainId === ChainId.MAINNET || Number(chainId) === 56
        ? `${prefix}/transaction/${data}`
        : `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'account':
    default: {
      return `${prefix}/account/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isFlowAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(18 - chars)}`
}

export function shortenENSName(name: string | null): string {
  if (!name) {
    return ''
  }

  return name.replace('.blocto.eth', '').replace('.blocto.xyz', '')
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenEthereumAddress(address: string, chars = 4): string {
  const parsed = isEthereumAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): number {
  return (1.0 * num) / 10000.0
}

export function calculateSlippageAmount(value: number, slippage: number): [number, number] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [(value * (10000 - slippage)) / 10000, (value * (10000 + slippage)) / 10000]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract | undefined {
  if (!isEthereumAddress(address) || address === AddressZero) {
    return
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function getSolanaExplorerLink(
  chainId: ChainId | undefined,
  hash: string,
  type: 'address' | 'tx' = 'address'
): string {
  const cluster = chainId === 1 ? '' : `?cluster=devnet`
  return `https://explorer.solana.com/${type}/${hash}${cluster}`
}

export function shortenSolanaAddress(address: string, chars = 4): string {
  const parsed = address
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars)}...${parsed.substring(address.length - chars)}`
}
