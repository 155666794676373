export enum Network {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  FLOW = 'FLOW',
  SOLANA = 'SOLANA'
}

export interface TokenInfo {
  readonly network: Network
  readonly chainId: number
  readonly address: string
  readonly name: string
  readonly decimals: number
  readonly symbol: string
  readonly logoURI?: string
  readonly tags?: string[]
}

export interface TokenInfoName {
  readonly name: string
  readonly symbol: string
  readonly networks?: string[]
}

export interface Pair {
  readonly liquidityToken: TokenInfo
  readonly token0: TokenInfo
  readonly token1: TokenInfo
  readonly price?: number
}

export interface Price {
  readonly price?: number
  readonly baseCurrency?: TokenInfo
  readonly quoteCurrency?: TokenInfo
}

export interface TransactionResponse {
  transactionId?: string
  hash?: string
  statusCode?: number
}

export interface TransactionReceipt {
  transactionHash: string
  status?: number
  statusCode?: number
}

export interface TransactionAbstract {
  hash: string
  receipt?: TransactionReceipt
  addedTime: number
  network: Network
}
