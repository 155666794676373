// Wrong vault type, destroy it
// destroy signer.load<@AnyResource>(from: TeleportedTetherToken.TokenStoragePath)
// signer.unlink(TeleportedTetherToken.TokenPublicReceiverPath)
// signer.unlink(TeleportedTetherToken.TokenPublicBalancePath)

const scriptExactFlowToUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    assert(token2Vault.balance > minAmountOut, message: "Output amount too small")

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptExactFlowToFusd = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault
    let token2Vault <- flowUsdtSwapProxy.swapToken1ForToken2(from: <-token1Vault)
    let token3Vault <- fusdUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token3Vault.balance > minAmountOut, message: "Output amount too small")

    if signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")

    fusdVault.deposit(from: <- token3Vault)
  }
}
`

const scriptExactFlowToBlt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault
    let token2Vault <- flowUsdtSwapProxy.swapToken1ForToken2(from: <-token1Vault)
    let token3Vault <- bltUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token3Vault.balance > minAmountOut, message: "Output amount too small")

    if signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault) == nil {
      // Create a new BloctoToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: /storage/bloctoTokenVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        /public/bloctoTokenReceiver,
        target: /storage/bloctoTokenVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        /public/bloctoTokenBalance,
        target: /storage/bloctoTokenVault
      )
    }

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    bloctoTokenVault.deposit(from: <- token3Vault)
  }
}
`

const scriptExactUsdtToFlow = `\
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let quote = FlowSwapPair.quoteSwapExactToken2ForToken1(amount: amountIn)

    let swapProxyRef = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    flowVault.deposit(from: <- token1Vault)
  }
}
`

const scriptExactUsdtToFusd = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FUSD from 0xFUSDADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    if signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
      ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    fusdVault.deposit(from: <- token1Vault)
  }
}
`

const scriptExactUsdtToBlt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }

    let bltVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
        ?? panic("Could not borrow a reference to Vault")

    let quote = BltUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: amountIn)

    let swapProxyRef = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    bltVault.deposit(from: <- token1Vault)
  }
}
`

const scriptExactFusdToUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FUSD from 0xFUSDADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    assert(token2Vault.balance > minAmountOut, message: "Output amount too small")

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptExactFusdToFlow = `\
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
      ?? panic("Could not borrow a reference to Vault")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault
    let token2Vault <- fusdUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)
    let token1Vault <- flowUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    flowVault.deposit(from: <- token1Vault)
  }
}`

const scriptExactFusdToBlt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import FUSD from 0xFUSDADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
      ?? panic("Could not borrow a reference to Vault")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault
    let token2Vault <- fusdUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)
    let token1Vault <- bltUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    bloctoTokenVault.deposit(from: <- token1Vault)
  }
}
`

const scriptExactBltToUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let bltVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
        ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- bltVault.withdraw(amount: amountIn) as! @BloctoToken.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    assert(token2Vault.balance > minAmountOut, message: "Output amount too small")

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptExactBltToFlow = `
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- bloctoTokenVault.withdraw(amount: amountIn) as! @BloctoToken.Vault
    let token2Vault <- bltUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)

    let quote = FlowSwapPair.quoteSwapExactToken2ForToken1(amount: amountIn)

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- flowUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    flowVault.deposit(from: <- token1Vault)
  }
}
`
const scriptExactBltToFusd = `\
import FUSD from 0xFUSDADDRESS
import BloctoToken from 0xBLTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

transaction(amountIn: UFix64, minAmountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- bloctoTokenVault.withdraw(amount: amountIn) as! @BloctoToken.Vault
    let token2Vault <- bltUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)

    let quote = FusdUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: amountIn)

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- fusdUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    assert(token1Vault.balance > minAmountOut, message: "Output amount too small")

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
      ?? panic("Could not borrow a reference to Vault")

    fusdVault.deposit(from: <- token1Vault)
  }
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptExactFlowToUsdt,
    FUSD: scriptExactFlowToFusd,
    BLT: scriptExactFlowToBlt
  },
  tUSDT: {
    FLOW: scriptExactUsdtToFlow,
    FUSD: scriptExactUsdtToFusd,
    BLT: scriptExactUsdtToBlt
  },
  FUSD: {
    tUSDT: scriptExactFusdToUsdt,
    FLOW: scriptExactFusdToFlow,
    BLT: scriptExactFusdToBlt
  },
  BLT: {
    tUSDT: scriptExactBltToUsdt,
    FLOW: scriptExactBltToFlow,
    FUSD: scriptExactBltToFusd
  }
}

export default scripts
