import { useEffect, useState, useMemo } from 'react'
import * as fcl from '@portto/fcl'
import * as types from '@onflow/types'
import { initiatePassOver, subscribeAccessToken } from './bindings'
import { ChainId } from '@uniswap/sdk'

type UrlList = { readonly [key: string]: string }

const HANDSHARE_URL_LIST: UrlList = {
  mainnet: 'https://flow-wallet.blocto.app/authn',
  testnet: 'https://flow-wallet-testnet.blocto.app/authn'
}

const ACCESS_NODE_LIST: UrlList = {
  // mainnet: 'https://flow-access-mainnet.portto.io',
  mainnet: 'https://access-mainnet-beta.onflow.org',
  testnet: 'https://access-testnet.onflow.org'
}

// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'

// calling multiple fcl.authenticate at the same time cause an error in non-prod env
// add this wrapper to make sure it doesn't get called too often
let timeout: any = null
function wrappedFCLAuthenticate() {
  if (timeout) {
    clearTimeout(timeout)
  }

  timeout = setTimeout(fcl.authenticate, 100)
}

export function useFclReact() {
  const [account, setAccount] = useState<string>()

  const [accessNodeUrl, handshakeUrl] = useMemo(() => {
    if (!NETWORK) return [undefined, undefined]

    return [
      ACCESS_NODE_LIST[NETWORK] || ACCESS_NODE_LIST['testnet'],
      HANDSHARE_URL_LIST[NETWORK] || HANDSHARE_URL_LIST['testnet']
    ]
  }, [])

  const connect = useMemo(() => {
    // eslint-disable-next-line
    if (!NETWORK || !handshakeUrl) return () => {}

    return function() {
      wrappedFCLAuthenticate()
      if (window.accessToken) {
        initiatePassOver(window.accessToken, handshakeUrl)
      }
    }
  }, [handshakeUrl])

  useEffect(() => {
    if (!accessNodeUrl || !handshakeUrl) return

    fcl
      .config()
      .put('accessNode.api', accessNodeUrl) // Flow testnet
      .put('challenge.handshake', handshakeUrl) // Blocto testnet wallet

    subscribeAccessToken(handshakeUrl)
  }, [accessNodeUrl, handshakeUrl])

  useEffect(() => {
    return fcl.currentUser().subscribe((user: any) => {
      setAccount(user?.addr)
    })
  }, [])

  return useMemo(
    () => ({
      fcl: accessNodeUrl ? fcl : undefined,
      types,
      authorization: fcl.currentUser().authorization,
      // @todo: remove chainId in response
      chainId: NETWORK === 'mainnet' ? ChainId.MAINNET : ChainId.RINKEBY,
      active: !!account,
      account: account,
      // eslint-disable-next-line
      connect: accessNodeUrl ? connect : () => {},
      // eslint-disable-next-line
      disconnect: accessNodeUrl ? fcl.unauthenticate : () => {}
    }),
    [account, accessNodeUrl, connect]
  )
}
