import { useEffect } from 'react'
import { useFclReact } from './useFclReact'
import useTimer from '../hooks/useTimer'
import useParsedQueryString from '../hooks/useParsedQueryString'
import swapExactIn from './transactions/swapExactIn'
import swapExactOut from './transactions/swapExactOut'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'
import { replaceContractAddresses } from './env'
import { TransactionResponse } from '../types'

export function useAutoTrade(interval?: number | undefined) {
  const addTransaction = useTransactionAdder()
  const parsedQs = useParsedQueryString()
  const { fcl, types, chainId, authorization, account } = useFclReact()
  const nonce = useTimer(
    interval ?? (parseInt((typeof parsedQs.interval === 'string' && parsedQs.interval) || '0') || 200000)
  )

  useEffect(() => {
    if (!account) {
      return
    }

    const inputAmount = (typeof parsedQs.amount === 'string' && parsedQs.amount) || '55.0'
    const inputSymbol = (typeof parsedQs.token === 'string' && parsedQs.token) || 'FLOW'
    const outputAmount = (typeof parsedQs.amount === 'string' && parsedQs.amount) || '55.0'
    const outputSymbol = 'FUSD'
    const isExactIn = nonce % 2 === 0

    let script = isExactIn
      ? swapExactIn[inputSymbol]?.[outputSymbol] ?? ''
      : swapExactOut[outputSymbol]?.[inputSymbol] ?? ''
    script = replaceContractAddresses(script, chainId)

    const formattedInput = isExactIn ? inputAmount : '4500.0'
    const formattedOutput = isExactIn ? '0.01' : outputAmount

    fcl
      .send([fcl.getLatestBlock()])
      .then(fcl.decode)
      .then((block: any) =>
        fcl.send([
          fcl.transaction(script),
          fcl.args([fcl.arg(formattedInput, types.UFix64), fcl.arg(formattedOutput, types.UFix64)]),
          fcl.limit(300),
          fcl.proposer(authorization),
          fcl.authorizations([authorization]),
          fcl.payer(authorization),
          fcl.ref(block.id)
        ])
      )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Auto swap ${isExactIn ? `${inputSymbol} for FUSD` : `FUSD for ${inputSymbol}`}`
        })

        return response.transactionId
      })
      .catch((error: Error) => {
        // if the user rejected the tx, pass this along
        if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
          throw new Error('Transaction rejected.')
        } else {
          // otherwise, the error was unexpected and we need to convey that
          console.error(`Swap failed`, error, script)
          throw new Error(`Swap failed: ${error.message}`)
        }
      })
  }, [account, fcl, types, nonce, chainId, authorization, addTransaction, parsedQs.amount, parsedQs.token])
}
