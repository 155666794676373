import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useFclReact } from '../../fcl-react'
import { useSolana } from '../../solana-react'
import { AppDispatch } from '../../state'
import { clearAllTransactions as clearAllEthTransactions } from '../../state/transactionsEthereum/actions'
import { clearAllTransactions as clearAllBscTransactions } from '../../state/transactionsBsc/actions'
import { clearAllTransactions as clearAllFlowTransactions } from '../../state/transactionsFlow/actions'
import { Network, TransactionAbstract } from '../../types'
import { clearAllTransactions as clearAllSolanaTransactions } from '../../state/transactionsSolana/actions'
import { AutoRow, HeaderRow as Row } from '../Row'
import Transaction from './Transaction'

import { SUPPORTED_WALLETS } from '../../constants'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import {
  getBSCscanLink,
  getEtherscanLink,
  getFvsLink,
  getSolanaExplorerLink,
  shortenEthereumAddress,
  shortenSolanaAddress
} from '../../utils'
import { injected } from '../../connectors'
import bscLogo from '../../assets/images/bsc-logo.svg'
import ethereumLogo from '../../assets/images/ethereum-logo.svg'
import flowIcon from '../../assets/images/flow-logo.svg'
import AccountSelectionOption from './AccountSelectionOption'
import solanaIcon from '../../assets/images/solana-logo.svg'
import { LinkStyledButton, TYPE } from '../../theme'
import { ChainId } from '@uniswap/sdk'

const HeaderRow = styled(Row)`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem;
  font-weight: 600;
  justify-content: center;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
  display: grid;
  gap: 1rem;
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 0.5rem 1.5rem;
  flex-grow: 1;
  display: flex;
  background-color: ${({ theme }) => theme.bg1};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
`

const TransactionList = styled.div`
  flex-grow: 1;
  padding: 0.5rem 0;
  overflow: auto;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  cursor: pointer;
  z-index: 1;
  transition: 0.2s transform;

  &:hover {
    transform: scale(1.05);
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const Separator = styled.div`
  width: calc(100% + 3rem);
  height: 1px;
  margin: 0 -1.5rem;
  background-color: ${({ theme }) => theme.bg3};
`

function renderTransactions(transactions: TransactionAbstract[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((tx, i) => {
        return <Transaction key={i} transaction={tx} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: TransactionAbstract[]
  confirmedTransactions: TransactionAbstract[]
  ENSName?: string
  openOptions: (arg: any) => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account: web3Account, connector } = useActiveWeb3React()
  const { account: flowAccount, disconnect, connect } = useFclReact()
  const { connect: solconnect, disconnect: soldisconnect, account: solanaAccount } = useSolana()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()

  const isETH = chainId === ChainId.MAINNET || chainId === ChainId.RINKEBY
  const isBSC = (chainId as number) === 56 || (chainId as number) === 97

  const ethAccount = isETH ? web3Account : null
  const bscAccount = isBSC ? web3Account : null

  function formatConnectorName() {
    const { ethereum } = window

    const isBlocto = !!(ethereum && ethereum.isBlocto)
    if (isBlocto) {
      return 'Blocto'
    }

    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k => SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isBlocto === (k === 'BLOCTO'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return name
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) {
      dispatch(clearAllEthTransactions({ chainId }))
      dispatch(clearAllBscTransactions({ chainId }))
      dispatch(clearAllFlowTransactions({ chainId }))
      dispatch(clearAllSolanaTransactions({ chainId }))
    }
  }, [dispatch, chainId])

  const reconnect = () => {
    disconnect()
    connect()
  }

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>Accounts</HeaderRow>

        <AccountSection>
          <AccountSelectionOption
            chain={Network.FLOW}
            onClick={flowAccount ? () => undefined : connect}
            onChange={reconnect}
            connectorName="Blocto"
            icon={<img src={flowIcon} alt="flow logo" />}
            account={flowAccount}
            address={flowAccount}
            walletLink={getFvsLink(chainId, flowAccount, 'account')}
          />

          <AccountSelectionOption
            chain={Network.ETHEREUM}
            onClick={ethAccount ? () => undefined : openOptions}
            onChange={openOptions}
            connectorName={formatConnectorName()}
            icon={<img src={ethereumLogo} alt="ethereum logo" />}
            account={ethAccount}
            address={ethAccount ? shortenEthereumAddress(ethAccount, 8) : ''}
            walletLink={getEtherscanLink(chainId, ENSName || ethAccount || undefined, 'address')}
          />

          <AccountSelectionOption
            chain={Network.BSC}
            onClick={bscAccount ? () => undefined : openOptions}
            onChange={openOptions}
            connectorName={formatConnectorName()}
            icon={<img src={bscLogo} alt="bsc logo" />}
            account={bscAccount}
            address={bscAccount ? shortenEthereumAddress(bscAccount, 8) : ''}
            walletLink={getBSCscanLink(chainId, ENSName || bscAccount || undefined, 'address')}
          />

          <AccountSelectionOption
            chain={Network.SOLANA}
            onClick={solanaAccount ? () => undefined : () => solconnect()}
            onChange={() => {
              // @todo: support other wallets with wallet adapter
              soldisconnect()
              solconnect()
            }}
            connectorName="Blocto"
            icon={<img src={solanaIcon} alt="solana logo" />}
            account={solanaAccount}
            address={solanaAccount ? shortenSolanaAddress(solanaAccount, 8) : ''}
            walletLink={getSolanaExplorerLink(chainId, solanaAccount, 'address')}
          />
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.small color={theme.text3} style={{ textTransform: 'uppercase' }}>
              Recent Transactions
            </TYPE.small>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>CLEAR</LinkStyledButton>
          </AutoRow>
          <Separator />
          <TransactionList>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </TransactionList>
        </LowerSection>
      ) : (
        <LowerSection>
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.body fontSize={14} color={theme.text1}>
              Your transactions will appear here...
            </TYPE.body>
          </AutoRow>
        </LowerSection>
      )}
    </>
  )
}
