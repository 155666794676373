import { useWeb3React } from '@web3-react/core'
import { darken } from 'polished'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'react-feather'
import styled from 'styled-components'
import { useFclReact } from '../../fcl-react'
import flowIcon from '../../assets/images/flow-logo.svg'
import { NetworkContextName } from '../../constants'
import { useWalletModalFlowToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactionsFlow/hooks'
import { TransactionDetails } from '../../state/transactionsFlow/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary, ButtonWallet } from '../Button'

import Loader from '../Loader'

import { RowBetween } from '../Row'
import WalletModal from '../WalletModalFlow'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  user-select: none;
  text-transform: none;
  height: 35px;

  :focus {
    outline: none;
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;

  :hover,
  :focus {
    border: none;
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.primary1) : darken(0.05, theme.bg3))};
  }
`

const Text = styled.span`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.5rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function StatusIcon() {
  return (
    <IconWrapper size={22}>
      <img src={flowIcon} alt={''} />
    </IconWrapper>
  )
}

function FclStatusInner({ pendingTransactions }: { pendingTransactions: string[] }) {
  const { t } = useTranslation()
  const { account, connect } = useFclReact()

  const hasPendingTransactions = !!pendingTransactions.length
  const toggleWalletModal = useWalletModalFlowToggle()

  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Loader stroke="white" />
            <Text>{pendingTransactions?.length} Pending</Text>
          </RowBetween>
        ) : (
          <>
            <StatusIcon />
            <Text>{shortenAddress(account)}</Text>
          </>
        )}
      </Web3StatusConnected>
    )
  } else {
    return (
      <ButtonWallet id="connect-wallet" onClick={connect}>
        <User size={18} style={{ marginRight: '10px' }} />
        {t('connectWallet')}
      </ButtonWallet>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <FclStatusInner pendingTransactions={pending} />
      <WalletModal pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
