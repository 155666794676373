import { ChainId } from '@uniswap/sdk'
import { TokenInfo, TokenInfoName } from '../types'
import { FLOW_TOKEN, TUSDT, USDT, FUSD, BLT, FLOW_TUSDT_LP, BLT_TUSDT_LP, BLT_SOLANA, BLT_BSC } from '../constants'

export type TokenAddressMap = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenAddress: string]: TokenInfo }> }>
export type TokenPairList = Readonly<{ [chainId in ChainId]: Readonly<[TokenInfo, TokenInfo][]> }>
export type TradeRoutes = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenPairAddress: string]: TokenInfo[] }> }>
export type LiquidityTokenMap = Readonly<{ [tokenAddress: string]: TokenInfo }>
export type TeleportTargetMap = Readonly<{ [tokenAddress: string]: TokenInfo }>

/**
 * An empty result, useful as a default.
 */
export const TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.RINKEBY],
    '0xab26e0a07d770ec1': TUSDT[ChainId.RINKEBY],
    '0xe223d8a629e49c68': FUSD[ChainId.RINKEBY],
    '0x6e0797ac987005f5': BLT[ChainId.RINKEBY]
  },
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {
    '0x1654653399040a61': FLOW_TOKEN[ChainId.MAINNET],
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x3c5959b568896393': FUSD[ChainId.MAINNET],
    '0x0f9df91c9121c460': BLT[ChainId.MAINNET]
  }
}

export const TELEPORT_TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {
    '0xab26e0a07d770ec1': TUSDT[ChainId.RINKEBY],
    '0x6e0797ac987005f5': BLT[ChainId.RINKEBY],
    BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef: BLT_SOLANA[ChainId.RINKEBY],
    '0xfB0727386DB1A630344a08467b45541bEC9bCf17': BLT_BSC[ChainId.RINKEBY]
  },
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x0f9df91c9121c460': BLT[ChainId.MAINNET],
    BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef: BLT_SOLANA[ChainId.MAINNET],
    '0x02Bdf640fba368E7Ba5c6429cCaF251512273865': BLT_BSC[ChainId.MAINNET]
  }
}

export const LOQUIDITY_TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.RINKEBY],
    '0xab26e0a07d770ec1': TUSDT[ChainId.RINKEBY],
    '0x6e0797ac987005f5': BLT[ChainId.RINKEBY]
  },
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {
    '0x1654653399040a61': FLOW_TOKEN[ChainId.MAINNET],
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x6e0797ac987005f5': BLT[ChainId.MAINNET]
  }
}

export const LIQUIDITY_TOKENS: LiquidityTokenMap = {
  '0x7e60df042a9c0868:0xab26e0a07d770ec1': FLOW_TUSDT_LP[ChainId.RINKEBY],
  '0xab26e0a07d770ec1:0x7e60df042a9c0868': FLOW_TUSDT_LP[ChainId.RINKEBY],
  '0x6e0797ac987005f5:0xab26e0a07d770ec1': BLT_TUSDT_LP[ChainId.RINKEBY],
  '0xab26e0a07d770ec1:0x6e0797ac987005f5': BLT_TUSDT_LP[ChainId.RINKEBY],
  '0x1654653399040a61:0xcfdd90d4a00f7b5b': FLOW_TUSDT_LP[ChainId.MAINNET],
  '0xcfdd90d4a00f7b5b:0x1654653399040a61': FLOW_TUSDT_LP[ChainId.MAINNET],
  '0x0f9df91c9121c460:0xcfdd90d4a00f7b5b': BLT_TUSDT_LP[ChainId.MAINNET],
  '0xcfdd90d4a00f7b5b:0x0f9df91c9121c460': BLT_TUSDT_LP[ChainId.MAINNET]
}

export const TOKEN_PAIRS: TokenPairList = {
  [ChainId.KOVAN]: [],
  [ChainId.RINKEBY]: [
    [FLOW_TOKEN[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
    [FUSD[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
    [BLT[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]]
  ],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.MAINNET]: [
    [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [FUSD[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [BLT[ChainId.MAINNET], TUSDT[ChainId.MAINNET]]
  ]
}

export const TRADE_ROUTES: TradeRoutes = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {
    '0x7e60df042a9c0868:0xe223d8a629e49c68': [
      FLOW_TOKEN[ChainId.RINKEBY],
      TUSDT[ChainId.RINKEBY],
      FUSD[ChainId.RINKEBY]
    ],
    '0xe223d8a629e49c68:0x7e60df042a9c0868': [
      FUSD[ChainId.RINKEBY],
      TUSDT[ChainId.RINKEBY],
      FLOW_TOKEN[ChainId.RINKEBY]
    ],
    '0x6e0797ac987005f5:0xe223d8a629e49c68': [BLT[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY], FUSD[ChainId.RINKEBY]],
    '0xe223d8a629e49c68:0x6e0797ac987005f5': [FUSD[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY], BLT[ChainId.RINKEBY]],
    '0x7e60df042a9c0868:0x6e0797ac987005f5': [
      FLOW_TOKEN[ChainId.RINKEBY],
      TUSDT[ChainId.RINKEBY],
      BLT[ChainId.RINKEBY]
    ],
    '0x6e0797ac987005f5:0x7e60df042a9c0868': [BLT[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY], FLOW_TOKEN[ChainId.RINKEBY]]
  },
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {
    '0x1654653399040a61:0x3c5959b568896393': [
      FLOW_TOKEN[ChainId.MAINNET],
      TUSDT[ChainId.MAINNET],
      FUSD[ChainId.MAINNET]
    ],
    '0x3c5959b568896393:0x1654653399040a61': [
      FUSD[ChainId.MAINNET],
      TUSDT[ChainId.MAINNET],
      FLOW_TOKEN[ChainId.MAINNET]
    ],
    '0x0f9df91c9121c460:0x3c5959b568896393': [BLT[ChainId.MAINNET], TUSDT[ChainId.MAINNET], FUSD[ChainId.MAINNET]],
    '0x3c5959b568896393:0x0f9df91c9121c460': [FUSD[ChainId.MAINNET], TUSDT[ChainId.MAINNET], BLT[ChainId.MAINNET]],
    '0x1654653399040a61:0x0f9df91c9121c460': [
      FLOW_TOKEN[ChainId.MAINNET],
      TUSDT[ChainId.MAINNET],
      BLT[ChainId.MAINNET]
    ],
    '0x0f9df91c9121c460:0x1654653399040a61': [BLT[ChainId.MAINNET], TUSDT[ChainId.MAINNET], FLOW_TOKEN[ChainId.MAINNET]]
  }
}

export const TELEPORT_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {
    '0xab26e0a07d770ec1': TUSDT[ChainId.RINKEBY]
  },
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET]
  }
}

export const TELEPORT_TARGET: TeleportTargetMap = {
  '0xab26e0a07d770ec1': USDT[ChainId.RINKEBY],
  '0xcfdd90d4a00f7b5b': USDT[ChainId.MAINNET],
  '0x6e0797ac987005f5': BLT_SOLANA[ChainId.RINKEBY],
  '0x0f9df91c9121c460': BLT_SOLANA[ChainId.MAINNET],
  // BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef: BLT[ChainId.RINKEBY],
  BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef: BLT[ChainId.MAINNET],
  '0xfB0727386DB1A630344a08467b45541bEC9bCf17': BLT[ChainId.RINKEBY],
  '0x02Bdf640fba368E7Ba5c6429cCaF251512273865': BLT[ChainId.MAINNET]
}

export const TELEPORT_TOKENS: any = {
  BLT: {
    FLOW: BLT,
    SOLANA: BLT_SOLANA,
    BSC: BLT_BSC
  },
  tUSDT: {
    FLOW: TUSDT
  },
  USDT: {
    ETHEREUM: USDT
  }
}

export const TELEPORT_SELECTIONS: TokenInfoName[] = [
  {
    name: 'BLT',
    symbol: 'BLT',
    networks: ['FLOW', 'SOLANA', 'BSC']
  },
  {
    name: 'USD Tether',
    symbol: 'tUSDT',
    networks: ['FLOW']
  },
  {
    name: 'USD Tether',
    symbol: 'USDT',
    networks: ['ETHEREUM']
  }
]
