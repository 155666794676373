import { ChainId } from '@uniswap/sdk'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TokenInfo } from '../../types'
import { BLT, TUSDT } from '../../constants'
import { useFclReact, useTradeExactIn, useTradeExactOut, useSwapFrozen, Trade } from '../../fcl-react'
import { useCurrency } from '../../hooks/Tokens'
import useDebounce from '../../hooks/useDebounce'
// import useGeoLocation from '../../hooks/useGeoLocation'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { isFlowAddress } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useTokenBalances } from '../wallet/flowHooks'
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { SwapState } from './reducer'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: TokenInfo) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: TokenInfo) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.address
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: TokenInfo): number | undefined {
  if (!value || !currency) {
    return undefined
  }

  return parseFloat(value)
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: TokenInfo }
  currencyBalances: { [field in Field]?: number }
  parsedAmount: number | undefined
  trade: Trade | undefined
  inputError?: string
} {
  const { account } = useFclReact()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId }
  } = useSwapState()

  const debouncedTypedValue = useDebounce(typedValue, 250)

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const relevantTokenBalances = useTokenBalances(account ?? undefined)

  // const geoLocation = useGeoLocation()
  const isFrozen = useSwapFrozen(inputCurrency ?? undefined, outputCurrency ?? undefined)

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(debouncedTypedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

  // Calculate price
  const tradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  )
  const tradeExactOut = useTradeExactOut(
    isExactIn ? undefined : parsedAmount,
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  )
  const trade = isExactIn ? tradeExactIn : tradeExactOut

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[inputCurrency?.address ?? ''],
    [Field.OUTPUT]: relevantTokenBalances[outputCurrency?.address ?? '']
  }

  const currencies: { [field in Field]?: TokenInfo } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  // if (
  //   ['US', 'CA'].includes(geoLocation) &&
  //   (currencies[Field.INPUT]?.symbol === 'FLOW' || currencies[Field.OUTPUT]?.symbol === 'FLOW')
  // ) {
  //   inputError = inputError ?? 'Trading disabled in your region'
  // }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts = trade && allowedSlippage && computeSlippageAdjustedAmounts(trade, allowedSlippage)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    isExactIn ? parsedAmount : slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null
  ]

  if (balanceIn !== undefined && amountIn && balanceIn < amountIn && inputCurrency?.symbol !== 'FUSD') {
    inputError = 'Insufficient ' + inputCurrency?.symbol + ' balance'
  }

  if (trade?.priceImpact === 100) {
    inputError = 'Insufficient tokens in the pool'
  }

  if (isFrozen) {
    inputError = 'Coming Soon'
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    trade,
    inputError
  }
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isFlowAddress(urlParam)
    return valid ? valid : BLT[ChainId.MAINNET].address
  }
  return BLT[ChainId.MAINNET].address
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  if (inputCurrency === outputCurrency) {
    if (outputCurrency === BLT[ChainId.MAINNET].address) {
      // both currencies are FLOW
      inputCurrency = TUSDT[ChainId.MAINNET].address
    } else if (typeof parsedQs.outputCurrency === 'string') {
      // user specified output currency
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField)
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useFclReact()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!chainId) return
    const parsed = queryParametersToSwapState(parsedQs)

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId
      })
    )

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}
