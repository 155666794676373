const scriptFlowUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(): [UFix64] {
    let poolAmounts = FlowSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptFusdUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(): [UFix64] {
    let poolAmounts = FusdUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptBltUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(): [UFix64] {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scripts: { [tokenName: string]: string } = {
  'FLOW:tUSDT': scriptFlowUsdt,
  'FUSD:tUSDT': scriptFusdUsdt,
  'BLT:tUSDT': scriptBltUsdt
}

export default scripts
