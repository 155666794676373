import { Currency, currencyEquals } from '@uniswap/sdk'
import { useMemo } from 'react'
import { useFclReact } from '../fcl-react'
import { useSwapTokenList, useTeleportTargetList, useTeleportTokenList } from '../state/lists/hooks'
import { useUserAddedTokens } from '../state/user/hooks'
import { TokenInfo } from '../types'

export function useAllSwapTokens(showLiquidityTokens?: boolean): { [address: string]: TokenInfo } {
  const { chainId } = useFclReact()
  const allTokens = useSwapTokenList(showLiquidityTokens)

  return useMemo(() => {
    if (!chainId) return {}
    return allTokens[chainId]
  }, [chainId, allTokens])
}

export function useAllTeleportTokens(): { [address: string]: TokenInfo } {
  const { chainId } = useFclReact()
  const allTokens = useTeleportTokenList()[chainId]
  const teleportList = useTeleportTargetList()

  return useMemo(() => {
    if (!chainId) return {}

    let result: { [address: string]: TokenInfo } = {}
    for (const key in allTokens) {
      const teleported = teleportList[key]
      result = {
        ...result,
        [key]: allTokens[key],
        [teleported.address]: teleportList[key]
      }
    }

    return result
  }, [chainId, allTokens, teleportList])
}

export function useAllTokens(): { [address: string]: TokenInfo } {
  const allSwapTokens = useAllSwapTokens()
  const allTeleportTokens = useAllTeleportTokens()

  return useMemo(() => {
    return {
      ...allSwapTokens,
      ...allTeleportTokens
    }
  }, [allSwapTokens, allTeleportTokens])
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency): boolean {
  const userAddedTokens = useUserAddedTokens()
  return !!userAddedTokens.find(token => currencyEquals(currency, token))
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): TokenInfo | undefined | null {
  const swapTokens = useAllTokens()
  const teleportTokens = useAllTeleportTokens()

  return useMemo(() => {
    return swapTokens[tokenAddress ?? ''] || teleportTokens[tokenAddress ?? '']
  }, [swapTokens, teleportTokens, tokenAddress])
}

export function useCurrency(currencyId: string | undefined): TokenInfo | null | undefined {
  return useToken(currencyId)
}
