const scriptFlowToExactUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapToken1ForExactToken2(amount: amount) / (1.0 - FlowSwapPair.feePercentage)
  let poolAmounts = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptFlowToExactFusd = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapToken1ForExactToken2(
    amount: FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount)
  ) / (1.0 - FlowSwapPair.feePercentage)

  let poolAmounts1 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptFlowToExactBlt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapToken1ForExactToken2(
    amount: BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount)
  ) / (1.0 - FlowSwapPair.feePercentage)

  let poolAmounts1 = FlowSwapPair.getPoolAmounts()
  let poolAmounts2 = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptUsdtToExactFlow = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - FlowSwapPair.feePercentage)

  let poolAmounts = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token1Amount / poolAmounts.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptUsdtToExactFusd = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount)

  return [quote, 1.0]
}
`

const scriptUsdtToExactBlt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - BltUsdtSwapPair.feePercentage)

  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token1Amount / poolAmounts.token2Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptFusdToExactUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amount)

  return [quote, 1.0]
}
`

const scriptFusdToExactFlow = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - FlowSwapPair.feePercentage)
  let quote = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: quoteUsdt)

  let poolAmounts2 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptFusdToExactBlt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - BltUsdtSwapPair.feePercentage)
  let quote = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: quoteUsdt)

  let poolAmounts2 = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptBltToExactUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = BltUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amount) / (1.0 - BltUsdtSwapPair.feePercentage)

  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptBltToExactFlow = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - FlowSwapPair.feePercentage)
  let quote = BltUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: quoteUsdt) / (1.0 - BltUsdtSwapPair.feePercentage)

  let poolAmounts1 = BltUsdtSwapPair.getPoolAmounts()
  let poolAmounts2 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptBltToExactFusd = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(
    amount: BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount) / (1.0 - BltUsdtSwapPair.feePercentage)
  )

  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptFlowToExactUsdt,
    FUSD: scriptFlowToExactFusd,
    BLT: scriptFlowToExactBlt
  },
  tUSDT: {
    FLOW: scriptUsdtToExactFlow,
    FUSD: scriptUsdtToExactFusd,
    BLT: scriptUsdtToExactBlt
  },
  FUSD: {
    tUSDT: scriptFusdToExactUsdt,
    FLOW: scriptFusdToExactFlow,
    BLT: scriptFusdToExactBlt
  },
  BLT: {
    tUSDT: scriptBltToExactUsdt,
    FLOW: scriptBltToExactFlow,
    FUSD: scriptBltToExactFusd
  }
}

export default scripts
