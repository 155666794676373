import React from 'react'
import styled from 'styled-components'
import { CheckCircle, AlertTriangle } from 'react-feather'

import { useActiveWeb3React } from '../../hooks'
import { getFvsLink } from '../../utils'
import { ExternalLink } from '../../theme'
import { useAllTransactions } from '../../state/transactionsFlow/hooks'
import Loader from '../Loader'
import flowIcon from '../../assets/images/flow-logo.svg'

const TransactionWrapper = styled.div``

const TransactionStatusText = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.5rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

const LogoWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.statusCode === 0 || typeof tx.receipt?.status === 'undefined')

  if (!chainId) return null

  return (
    <TransactionWrapper>
      <TransactionState href={getFvsLink(chainId, hash, 'transaction')} pending={pending} success={success}>
        <LogoWrapper size={24}>
          <img src={flowIcon} alt={'flow logo'} />
        </LogoWrapper>
        <TransactionStatusText>{summary ?? hash} ↗</TransactionStatusText>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size="16" /> : <AlertTriangle size="16" />}
        </IconWrapper>
      </TransactionState>
    </TransactionWrapper>
  )
}
