const script = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount, proxyHolder: AuthAccount) {
    let blockTokenVaultRef = accu.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>() ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

export default script
