import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { getTeleportStatus, TeleportStatus } from '../../teleport'
import { Network } from '../../types'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { useTransactionAdder } from '../transactionsFlow/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'
import { ChainId } from '@uniswap/sdk'

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: {}; lastCheckedBlockNumber?: number }
): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

const chainIdMapping: { [id: number]: number } = {
  56: ChainId.MAINNET,
  97: ChainId.RINKEBY
}

export default function Updater(): null {
  const { chainId, library } = useActiveWeb3React()

  const lastBlockNumber = useBlockNumber()
  const addTransaction = useTransactionAdder()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactionsBsc']>(state => state.transactionsBsc)

  const transactions = chainId ? state[chainIdMapping[chainId]] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return

    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        library
          .getTransactionReceipt(hash)
          .then(async receipt => {
            if (receipt) {
              if ((transactions[hash].summary ?? '').match(/Teleport.+from Binance Smart Chain/)) {
                const status = await getTeleportStatus(
                  chainIdMapping[chainId],
                  Network.BSC,
                  `0x${hash.replace('0x', '')}`,
                  'blt'
                )

                if (
                  status.tx_hash !== hash &&
                  (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED)
                ) {
                  addTransaction(
                    {
                      transactionId: status.tx_hash.replace('0x', '')
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId: chainIdMapping[chainId],
                      hash,
                      receipt: {
                        blockHash: receipt.blockHash,
                        blockNumber: receipt.blockNumber,
                        contractAddress: receipt.contractAddress,
                        from: receipt.from,
                        status: receipt.status,
                        to: receipt.to,
                        transactionHash: receipt.transactionHash,
                        transactionIndex: receipt.transactionIndex
                      }
                    })
                  )

                  return
                }
              } else {
                dispatch(
                  finalizeTransaction({
                    chainId: chainIdMapping[chainId],
                    hash,
                    receipt: {
                      blockHash: receipt.blockHash,
                      blockNumber: receipt.blockNumber,
                      contractAddress: receipt.contractAddress,
                      from: receipt.from,
                      status: receipt.status,
                      to: receipt.to,
                      transactionHash: receipt.transactionHash,
                      transactionIndex: receipt.transactionIndex
                    }
                  })
                )

                addPopup(
                  {
                    txn: {
                      hash,
                      success: receipt.status === 1,
                      summary: transactions[hash]?.summary,
                      network: Network.BSC
                    }
                  },
                  hash
                )

                return
              }
            }

            dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }))
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, library, transactions, lastBlockNumber, dispatch, addPopup, addTransaction])

  return null
}
