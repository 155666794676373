const scriptExactFlowToUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - FlowSwapPair.feePercentage))
  let poolAmounts = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactFlowToFusd = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapExactToken2ForToken1(
    amount: FlowSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - FlowSwapPair.feePercentage))
  )

  let poolAmounts1 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactFlowToBlt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = FlowSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - FlowSwapPair.feePercentage))
  let quote = BltUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: quoteUsdt * (1.0 - BltUsdtSwapPair.feePercentage))

  let poolAmounts1 = FlowSwapPair.getPoolAmounts()
  let poolAmounts2 = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactUsdtToFlow = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FlowSwapPair.quoteSwapExactToken2ForToken1(amount: amount * (1.0 - FlowSwapPair.feePercentage))

  let poolAmounts = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token1Amount / poolAmounts.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactUsdtToFusd = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: amount)

  return [quote, 1.0]
}
`

const scriptExactUsdtToBlt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = BltUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: amount * (1.0 - BltUsdtSwapPair.feePercentage))

  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token1Amount / poolAmounts.token2Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactFusdToUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = FusdUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount)

  return [quote, 1.0]
}
`

const scriptExactFusdToFlow = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = FusdUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount)
  let quote = FlowSwapPair.quoteSwapExactToken2ForToken1(amount: quoteUsdt * (1.0 - FlowSwapPair.feePercentage))
  
  let poolAmounts2 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactFusdToBlt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = FusdUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount)
  let quote = BltUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: quoteUsdt * (1.0 - BltUsdtSwapPair.feePercentage))
  
  let poolAmounts2 = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactBltToUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quote = BltUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - BltUsdtSwapPair.feePercentage))

  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactBltToFlow = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = BltUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - BltUsdtSwapPair.feePercentage))
  let quote = FlowSwapPair.quoteSwapExactToken2ForToken1(amount: quoteUsdt * (1.0 - FlowSwapPair.feePercentage))
  
  let poolAmounts1 = BltUsdtSwapPair.getPoolAmounts()
  let poolAmounts2 = FlowSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts1.token2Amount / poolAmounts1.token1Amount)
    * (poolAmounts2.token1Amount / poolAmounts2.token2Amount)
    * (1.0 - FlowSwapPair.feePercentage)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scriptExactBltToFusd = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(amount: UFix64): [UFix64] {
  let quoteUsdt = BltUsdtSwapPair.quoteSwapExactToken1ForToken2(amount: amount * (1.0 - BltUsdtSwapPair.feePercentage))
  let quote = FusdUsdtSwapPair.quoteSwapExactToken2ForToken1(amount: quoteUsdt)
  
  let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

  let currentPrice = (poolAmounts.token2Amount / poolAmounts.token1Amount)
    * (1.0 - BltUsdtSwapPair.feePercentage)

  return [quote, currentPrice]
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptExactFlowToUsdt,
    FUSD: scriptExactFlowToFusd,
    BLT: scriptExactFlowToBlt
  },
  tUSDT: {
    FLOW: scriptExactUsdtToFlow,
    FUSD: scriptExactUsdtToFusd,
    BLT: scriptExactUsdtToBlt
  },
  FUSD: {
    tUSDT: scriptExactFusdToUsdt,
    FLOW: scriptExactFusdToFlow,
    BLT: scriptExactFusdToBlt
  },
  BLT: {
    FLOW: scriptExactBltToFlow,
    tUSDT: scriptExactBltToUsdt,
    FUSD: scriptExactBltToFusd
  }
}

export default scripts
