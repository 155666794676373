const scriptCheckUSDT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

pub fun main(account: Address): Bool {
    let receiverRef = getAccount(account).getCapability(TeleportedTetherToken.TokenPublicReceiverPath)
        .borrow<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>()

    return receiverRef != nil
}
`

const scriptCheckFUSD = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FUSD from 0xFUSDADDRESS

pub fun main(account: Address): Bool {
    let receiverRef = getAccount(account).getCapability(/public/fusdReceiver)
        .borrow<&FUSD.Vault{FungibleToken.Receiver}>()

    return receiverRef != nil
}
`

const scriptCheckBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS

pub fun main(account: Address): Bool {
    let receiverRef = getAccount(account).getCapability(BloctoToken.TokenPublicReceiverPath)
        .borrow<&BloctoToken.Vault{FungibleToken.Receiver}>()

    return receiverRef != nil
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptCheckUSDT,
  FUSD: scriptCheckFUSD,
  BLT: scriptCheckBLT
}

export default scripts
