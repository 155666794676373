const script = `\
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS)
    .getCapability(/public/teleportedTetherTokenTeleportUser)
    .borrow<&TeleportedTetherToken.TeleportAdmin{TeleportedTetherToken.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptSPLBLT = `\
import TeleportCustodySolana from 0xTELEPORTSOLANAADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptBEP20BLT = `\
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const sciprts: { [token1Name: string]: { [token2Name: string]: string } } = {
  USDT_ETHEREUM: {
    // eslint-disable-next-line
    tUSDT_FLOW: script
  },
  // eslint-disable-next-line
  tUSDT_FLOW: {
    USDT_ETHEREUM: script
  },
  BLT_FLOW: {
    BLT_SOLANA: scriptSPLBLT,
    BLT_BSC: scriptBEP20BLT
  },
  BLT_SOLANA: {
    BLT_FLOW: scriptSPLBLT
  },
  BLT_BSC: {
    BLT_FLOW: scriptBEP20BLT
  }
}

export default sciprts
