const script = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodySolana from 0xTELEPORTSOLANAADDRESS

transaction(amount: UFix64, to: String, toAddressType: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount,proxyHolder: AuthAccount) {
    let blockTokenVaultRef = accu.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>() ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex(), toAddressType: toAddressType)
  }
}
`

export default script
