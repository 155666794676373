import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Trade } from '../../fcl-react'
import { TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { TeleportCallbackError, ErrorText } from './styleds'

export default function TeleportModalFooter({
  trade,
  onConfirm,
  teleportErrorMessage
}: {
  trade: Trade
  onConfirm: () => void
  teleportErrorMessage: string | undefined
}) {
  const theme = useContext(ThemeContext)

  return (
    <>
      <AutoColumn gap="0px">
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Teleport Fee
            </TYPE.black>
            <QuestionHelper text="A fixed amount is charged in each teleport to cover the cost of teleport service and network transaction fee." />
          </RowFixed>
          <TYPE.black fontSize={14}>
            {trade.fee ? trade.fee.toFixed(3) + ' ' + trade.inputCurrency.symbol.replace('t', '') : '-'}
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Process Time
            </TYPE.black>
            <QuestionHelper text="Teleporting more than 1000 USDT requires manual auditing and can take up to 24 hours." />
          </RowFixed>
          <ErrorText fontWeight={500} fontSize={14} severity={trade.inputAmount >= 100000 ? 2 : 0}>
            {trade.inputAmount >= 100000 ? '<24 hours' : '~15 minutes'}
          </ErrorText>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        <ButtonError onClick={onConfirm} style={{ margin: '10px 0 0 0' }} id="confirm-teleport-or-send">
          <Text fontSize={20} fontWeight={500}>
            Confirm Teleport
          </Text>
        </ButtonError>

        {teleportErrorMessage ? <TeleportCallbackError error={teleportErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
