import { useMemo, useState, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../state/index'
import BloctoSDK from '@blocto/sdk'
import { SolanaContext } from './SolanaContext'
import { TokenInfo } from '../types'
import { Trade } from '../fcl-react'
import { teleportIn } from './teleport'
import { PublicKey, Connection } from '@solana/web3.js'
import { useFclReact } from '../fcl-react/useFclReact'
import { BLT_TOKEN_MINT } from './env'
import { useTransactionAdder } from '../state/transactionsSolana/hooks'

export function useSolana() {
  const { solana, setSolana } = useContext<any>(SolanaContext)
  const [account, setAccount] = useState<string>('')
  const { chainId } = useFclReact()

  const API_ENDPOINT = chainId === 1 ? 'https://blocto.rpcpool.com' : 'https://api.devnet.solana.com'
  const connection = new Connection(API_ENDPOINT)

  const net = useMemo(() => {
    switch (chainId) {
      case 1:
        return 'mainnet-beta'
      case 4:
        return 'devnet'
      default:
        return 'mainnet-beta'
    }
  }, [chainId])
  const bloctoSDK = useRef(
    new BloctoSDK({
      solana: {
        net: net,
        rpc: API_ENDPOINT
      }
    })
  )

  useEffect(() => {
    if (solana?.accounts) {
      setAccount(solana.accounts[0])
    }
  }, [solana])

  const connect = useMemo(() => {
    return async () => {
      if (bloctoSDK.current.solana) {
        await bloctoSDK.current.solana.connect()
        const { accounts = [] } = bloctoSDK.current.solana
        setAccount(accounts[0])
        setSolana(bloctoSDK.current.solana)
      }
    }
  }, [setSolana])

  const disconnect = useMemo(() => {
    return () => {
      if (bloctoSDK.current.solana) {
        bloctoSDK.current.solana.disconnect()
        setAccount('')
      }
    }
  }, [])

  return useMemo(
    () => ({
      chainId,
      connect,
      disconnect,
      account,
      net,
      solana,
      connection
    }),
    [connect, disconnect, account, net, solana, chainId, connection]
  )
}

export function useBLTSOLBalance(uncheckedAddress: string | null, token?: TokenInfo | null): number | undefined {
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const result = token ? wallet.balances?.[token.address] : undefined
  return result
}

export enum TeleportCallbackState {
  INVALID,
  LOADING,
  VALID
}

export function useTeleportCallback(
  trade: Trade | undefined // trade to execute, required
): { state: TeleportCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { solana } = useContext<any>(SolanaContext)
  const { account: flowAccount } = useFclReact()
  const { account: solAccount, connection } = useSolana()
  const wallet = useRef<any>()
  const addTransaction = useTransactionAdder()
  useEffect(() => {
    if (solAccount && solana) {
      const pubKey = new PublicKey(solAccount)
      wallet.current = Object.create(solana)
      wallet.current.publicKey = pubKey
    }
  }, [solAccount, solana])

  const formattedInput = trade ? parseInt(trade.inputAmount.toFixed(8).replace('.', '')) : 0

  const outputCallback = () => {
    return !flowAccount || !trade
      ? null
      : async function onTeleport(): Promise<string> {
          return teleportIn(connection, wallet.current, BLT_TOKEN_MINT, formattedInput, 8, flowAccount)
            .then(response => {
              const outputSymbol = trade.outputCurrency.symbol
              const inputAmount = trade.inputAmount.toFixed(4)

              const summary = `Teleport ${inputAmount} ${outputSymbol} from Solana to Flow`
              addTransaction(
                { transactionId: response },
                {
                  summary
                }
              )
              return response
            })
            .catch((error: Error) => {
              // if the user rejected the tx, pass this along
              if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
                throw new Error('Transaction rejected.')
              } else {
                // otherwise, the error was unexpected and we need to convey that
                console.error(`Teleport failed`, error)
                throw new Error(`Teleport failed: ${error.message}`)
              }
            })
        }
  }
  return {
    state: TeleportCallbackState.VALID,
    callback: outputCallback(),
    error: null
  }
}
