import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactionsFlow from './transactionsFlow/reducer'
import transactionsEthereum from './transactionsEthereum/reducer'
import transactionsBsc from './transactionsBsc/reducer'
import transactionsSolana from './transactionsSolana/reducer'
import swap from './swap/reducer'
import teleport from './teleport/reducer'
import wallet from './wallet/reducer'
import mint from './mint/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'

const PERSISTED_KEYS: string[] = [
  'user',
  'transactionsFlow',
  'transactionsEthereum',
  'transactionsBsc',
  'transactionsSolana'
]

const store = configureStore({
  reducer: {
    application,
    user,
    transactionsFlow,
    transactionsEthereum,
    transactionsBsc,
    transactionsSolana,
    swap,
    teleport,
    wallet,
    mint,
    burn,
    multicall
  },
  middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
