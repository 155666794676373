import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonConfirmed } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmTeleportModal from '../../components/teleport/ConfirmTeleportModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedTeleportDetailsDropdown from '../../components/teleport/AdvancedTeleportDetailsDropdown'
import { ArrowWrapper, BottomGrouping, TeleportCallbackError, Wrapper } from '../../components/teleport/styleds'
import ProgressSteps from '../../components/ProgressSteps'
import { useWalletModalMixedToggle } from '../../state/application/hooks'

import { useTeleportCallback as useTeleportInCallback } from '../../hooks/useTeleportCallback'
import { useApproveAndTeleportCallback } from '../../hooks/useApproveAndTeleportCallback'
import {
  useFclReact,
  useTeleportCallback as useTeleportOutCallback,
  Trade,
  EnableState,
  useEnableCallback
} from '../../fcl-react'
import { Field } from '../../state/teleport/actions'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import {
  useDefaultsFromURLSearch,
  useDerivedTeleportInfo,
  useTeleportActionHandlers,
  useTeleportState
} from '../../state/teleport/hooks'
import { TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Network } from '../../types'
import Loader from '../../components/Loader'
import { TELEPORT_TOKENS } from '../../constants/lists'
import { useTeleportCallback as useTeleportSOLCallback, useSolana } from '../../solana-react/useSolana'
import { useActiveWeb3React } from '../../hooks'

// const IS_FROM_BLOCTO = window.hasOwnProperty('ethereum') && window.ethereum?.isBlocto
const IS_FROM_BLOCTO = false

export default function Teleport() {
  useDefaultsFromURLSearch()

  const { account: flowAccount, chainId } = useFclReact()
  const { account: ethAccount } = useActiveWeb3React()
  const { account: solAccount } = useSolana()
  const theme = useContext(ThemeContext)

  // teleport state
  const { independentField, typedValue } = useTeleportState()
  const { trade, currencyBalances, parsedAmount, currencies, inputError: teleportInputError } = useDerivedTeleportInfo()

  const noEnoughLiquidity = trade?.inputAmount === Number.MAX_VALUE

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
  }

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useTeleportActionHandlers()
  const isValid = !teleportInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, teleportErrorMessage, attemptingTxn, txHash }, setTeleportState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    teleportErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    teleportErrorMessage: undefined,
    txHash: undefined
  })

  const dependentCurrency = currencies[dependentField]
  const dependentAmount =
    parsedAmounts[dependentField] === Number.MAX_VALUE
      ? ''
      : (parsedAmounts[dependentField] && parsedAmounts[dependentField]?.toFixed(dependentCurrency?.decimals)) || ''

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: dependentAmount
  }

  // check whether the user has approved the router on the input token
  const [enableState, enableCallback] = useEnableCallback(trade?.outputCurrency)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [enableSubmitted, setEnableSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (enableState === EnableState.PENDING) {
      setEnableSubmitted(true)
    }
  }, [enableState, enableSubmitted])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: number | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
    currencies[Field.INPUT]?.symbol
  )
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT] === maxAmountInput)

  // the callback to execute the teleport
  const { callback: teleportInCallback, error: teleportInCallbackError } = useTeleportInCallback(trade)
  const { callback: teleportOutCallback, error: teleportOutCallbackError } = useTeleportOutCallback(trade)
  const {
    callback: approveAndTeleportCallback,
    error: approveAndTeleportCallbackError
  } = useApproveAndTeleportCallback(trade)
  const { callback: teleportSOLCallback, error: teleportSOLCallbackError } = useTeleportSOLCallback(trade)

  let teleportCallback: (() => Promise<string>) | null = null
  let teleportCallbackError: string | null = null

  const isTeleportingToFlow = trade?.outputCurrency.network === Network.FLOW
  const isBatchable = isTeleportingToFlow && IS_FROM_BLOCTO && approval !== ApprovalState.APPROVED

  switch (trade?.inputCurrency.network) {
    case Network.FLOW:
      teleportCallbackError = teleportOutCallbackError
      teleportCallback = teleportOutCallback
      break
    case Network.ETHEREUM:
    case Network.BSC:
      teleportCallbackError = teleportInCallbackError
      teleportCallback = teleportInCallback
      break
    case Network.SOLANA:
      teleportCallbackError = teleportSOLCallbackError
      teleportCallback = teleportSOLCallback
      break
    default:
      break
  }

  if (isBatchable) {
    teleportCallbackError = approveAndTeleportCallbackError
    teleportCallback = approveAndTeleportCallback
  }

  const handleTeleport = useCallback(() => {
    if (!teleportCallback) {
      return
    }

    setTeleportState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      teleportErrorMessage: undefined,
      txHash: undefined
    })

    teleportCallback()
      .then(hash => {
        setTeleportState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          teleportErrorMessage: undefined,
          txHash: hash
        })

        ReactGA.event({
          category: 'Teleport',
          action: 'Teleport w/o Send',
          label: [trade?.inputCurrency?.symbol, trade?.outputCurrency?.symbol].join('/')
        })
      })
      .catch(error => {
        setTeleportState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          teleportErrorMessage: error?.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, showConfirm, teleportCallback, trade])

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // if the user uses blocto-injected web3, combine approve & swap steps
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isBatchable &&
    !teleportInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED))

  const showEnableFlow =
    !teleportInputError &&
    (enableState === EnableState.NOT_ENABLED ||
      enableState === EnableState.PENDING ||
      enableState === EnableState.UNKNOWN ||
      (enableSubmitted && enableState === EnableState.ENABLED))

  const canSkipApprove = enableSubmitted && enableState === EnableState.ENABLED && isBatchable

  let steps: boolean[] = []

  if (showEnableFlow) {
    steps = [...steps, enableState === EnableState.ENABLED]
  }

  if (showApproveFlow) {
    steps = [...steps, approval === ApprovalState.APPROVED]
  }

  const handleConfirmDismiss = useCallback(() => {
    setTeleportState({ showConfirm: false, tradeToConfirm, attemptingTxn, teleportErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, teleportErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setTeleportState({ tradeToConfirm: trade, teleportErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, teleportErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      /* help select outputCurrency */
      switch (inputCurrency.symbol) {
        case 'BLT':
          if (inputCurrency.network === 'FLOW') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['BLT']['SOLANA'][chainId])
          }
          if (inputCurrency.network === 'SOLANA' || inputCurrency.network === 'BSC') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['BLT']['FLOW'][chainId])
          }
          break
        case 'USDT':
          onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['tUSDT']['FLOW'][chainId])
          break
        case 'tUSDT':
          onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['USDT']['ETHEREUM'][chainId])
          break
      }
    },
    [onCurrencySelection, chainId]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput !== undefined && onUserInput(Field.INPUT, maxAmountInput.toString())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const toggleWalletModal = useWalletModalMixedToggle()
  const { t } = useTranslation()

  const connectWallets = useMemo(() => {
    const networkConnected = (INPUT: Field.INPUT | Field.OUTPUT) => {
      if (currencies[INPUT]?.network === Network.FLOW) {
        return flowAccount
      }
      if (currencies[INPUT]?.network === Network.ETHEREUM || currencies[INPUT]?.network === Network.BSC) {
        return ethAccount
      }
      if (currencies[INPUT]?.network === Network.SOLANA) {
        return solAccount
      } else {
        return undefined
      }
    }
    return networkConnected(Field.INPUT) && networkConnected(Field.OUTPUT)
  }, [flowAccount, ethAccount, solAccount, currencies])

  const connectWalletsText = useCallback(() => {
    const networks = [currencies[Field.INPUT]?.network, currencies[Field.OUTPUT]?.network]
    if (networks.includes(Network.FLOW) && !flowAccount) {
      return 'connect flow'
    } else if (networks.includes(Network.ETHEREUM) && !ethAccount) {
      return 'connect ethereum'
    } else if (networks.includes(Network.BSC) && !ethAccount) {
      return 'connect bsc'
    } else if (networks.includes(Network.SOLANA) && !solAccount) {
      return 'connect solana'
    } else {
      return t('connectWallets')
    }
  }, [flowAccount, ethAccount, solAccount, currencies, t])

  const renderNextStep = () => {
    if (!connectWallets) {
      return <ButtonLight onClick={toggleWalletModal}>{connectWalletsText()}</ButtonLight>
    } else if (noEnoughLiquidity) {
      return (
        <GreyCard style={{ textAlign: 'center' }}>
          <TYPE.main mb="4px">{t('insufficientLiquidity')}</TYPE.main>
        </GreyCard>
      )
    } else if (showApproveFlow || showEnableFlow) {
      return (
        <RowBetween>
          {enableState !== EnableState.ENABLED && (
            <ButtonConfirmed
              onClick={enableCallback}
              disabled={enableState !== EnableState.NOT_ENABLED || enableSubmitted}
              altDisabledStyle={enableState === EnableState.PENDING} // show solid button while waiting
            >
              {enableState === EnableState.PENDING ? (
                <AutoRow gap="6px" justify="center">
                  Enabling <Loader stroke="white" />
                </AutoRow>
              ) : (
                'Enable ' + currencies[Field.OUTPUT]?.symbol
              )}
            </ButtonConfirmed>
          )}
          {enableState === EnableState.ENABLED && approval !== ApprovalState.APPROVED && !canSkipApprove && (
            <ButtonConfirmed
              onClick={approveCallback}
              disabled={
                approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || enableState !== EnableState.ENABLED
              }
              altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
            >
              {approval === ApprovalState.PENDING ? (
                <AutoRow gap="6px" justify="center">
                  Approving <Loader stroke="white" />
                </AutoRow>
              ) : (
                'Approve ' + currencies[Field.INPUT]?.symbol
              )}
            </ButtonConfirmed>
          )}
          {enableState === EnableState.ENABLED && (approval === ApprovalState.APPROVED || canSkipApprove) && (
            <ButtonError
              onClick={() => {
                setTeleportState({
                  tradeToConfirm: trade,
                  attemptingTxn: false,
                  teleportErrorMessage: undefined,
                  showConfirm: true,
                  txHash: undefined
                })
              }}
              id="teleport-button"
              disabled={!isValid}
            >
              <Text fontSize={16} fontWeight={500}>
                {teleportInputError ? teleportInputError : 'Teleport'}
              </Text>
            </ButtonError>
          )}
        </RowBetween>
      )
    } else {
      return (
        <ButtonError
          onClick={() => {
            setTeleportState({
              tradeToConfirm: trade,
              attemptingTxn: false,
              teleportErrorMessage: undefined,
              showConfirm: true,
              txHash: undefined
            })
          }}
          id="teleport-button"
          disabled={!isValid || !!teleportCallbackError}
        >
          <Text fontSize={16} fontWeight={600}>
            {teleportInputError ? teleportInputError : 'Teleport'}
          </Text>
        </ButtonError>
      )
    }
  }

  return (
    <>
      <AppBody>
        <SwapPoolTabs active={'teleport'} />
        <Wrapper id="teleport-page">
          <ConfirmTeleportModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            onConfirm={handleTeleport}
            teleportErrorMessage={teleportErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <CurrencyInputPanel
              label="From"
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              showTeleportTokens
              id="teleport-currency-input"
              isTeleport={true}
              isMainSelector={true}
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown
                    size="16"
                    onClick={() => {
                      onSwitchTokens()
                    }}
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                  />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label="To"
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              showTeleportTokens
              id="teleport-currency-output"
              isTeleport={true}
            />
          </AutoColumn>
          <BottomGrouping>
            {renderNextStep()}
            {(showApproveFlow || showEnableFlow) && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={steps} />
              </Column>
            )}
            {teleportErrorMessage ? <TeleportCallbackError error={teleportErrorMessage} /> : null}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
      <AdvancedTeleportDetailsDropdown trade={trade} />
    </>
  )
}
