import React from 'react'
import styled from 'styled-components'
import Copy from './Copy'
import { ArrowRight } from 'react-feather'
import { darken } from 'polished'
import { ButtonSmall } from '../Button'
import { Network } from '../../types'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const InfoCard = styled.div<{ onClick?: Function }>`
  padding: 1.5rem 1rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: ${({ onClick }) => (Boolean(onClick) ? 'pointer' : 'inherit')};

  &:hover {
    background-color: ${({ theme, onClick }) => (Boolean(onClick) ? darken(0.01, theme.bg2) : theme.bg2)};
  }
`

const AccountControl = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
`

const WalletAddress = styled.a`
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
  }
`

const WalletAction = styled(ButtonSmall)`
  flex: 0 0 auto;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const AccountConnect = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`

interface AccountSelectionOptionInterface {
  chain: Network
  onClick: (arg?: any) => void
  onChange: (arg?: any) => void
  connectorName: string
  icon: React.ReactNode
  account?: string | null
  address?: string | null
  walletLink: string
}

const CHAIN_NAME_MAPPING: { [network in Network]: string } = {
  [Network.ETHEREUM]: 'Ethereum',
  [Network.BSC]: 'Binance Smart Chain',
  [Network.SOLANA]: 'Solana',
  [Network.FLOW]: 'Flow'
}

const AccountSelectionOption = ({
  chain,
  onClick,
  onChange,
  connectorName,
  icon,
  account,
  address,
  walletLink
}: AccountSelectionOptionInterface) => (
  <InfoCard onClick={() => onClick(chain)}>
    <IconWrapper size={40}>{icon}</IconWrapper>
    {account ? (
      <AccountControl>
        <WalletAddress href={walletLink} target="_blank">
          {address}
        </WalletAddress>
        <WalletName>
          {connectorName}
          <Copy toCopy={account}>
            <span style={{ marginLeft: '4px' }}>Copy Address</span>
          </Copy>
        </WalletName>
      </AccountControl>
    ) : (
      <AccountConnect>
        Connect to {CHAIN_NAME_MAPPING[chain]} account
        <ArrowRight size={24} />
      </AccountConnect>
    )}
    {account && <WalletAction onClick={() => onChange(chain)}>Change</WalletAction>}
  </InfoCard>
)

export default AccountSelectionOption
