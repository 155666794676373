import { ChainId } from '@uniswap/sdk'

enum ContractId {
  FUNGIBLE_TOKEN_ADDRESS = 'FUNGIBLE_TOKEN_ADDRESS',
  FLOW_TOKEN_ADDRESS = 'FLOW_TOKEN_ADDRESS',
  TELEPORTED_USDT_ADDRESS = 'TELEPORTED_USDT_ADDRESS',
  FLOW_SWAP_PAIR_ADDRESS = 'FLOW_SWAP_PAIR_ADDRESS',
  FLOW_USD_ADDRESS = 'FLOW_USD_ADDRESS',
  FUSD_USDT_SWAP_PAIR_ADDRESS = 'FUSD_USDT_SWAP_PAIR_ADDRESS',
  TOKEN_POOL_ADDRESS = 'TOKEN_POOL_ADDRESS',
  TELEPORT_BLT_ADMIN_ADDRESS = 'TELEPORT_BLT_ADMIN_ADDRESS',
  TELEPORT_USDT_ADMIN_ADDRESS = 'TELEPORT_USDT_ADMIN_ADDRESS',
  FLOW_STORAGE_FEES_ADDRESS = 'FLOW_STORAGE_FEES_ADDRESS',
  BLOCTO_TOKEN_ADDRESS = 'BLOCTO_TOKEN_ADDRESS',
  BLT_USDT_SWAP_PAIR_ADDRESS = 'BLT_USDT_SWAP_PAIR_ADDRESS',
  TELEPORT_SOLANA_ADDRESS = 'TELEPORT_SOLANA_ADDRESS',
  TELEPORT_BSC_BLT_ADDRESS = 'TELEPORT_BSC_BLT_ADDRESS'
}

type ContractAddressList = {
  readonly [contractId in ContractId]: string
}

const FAKE_ADDRESSES: ContractAddressList = {
  [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
  [ContractId.TELEPORTED_USDT_ADDRESS]: '0xab26e0a07d770ec1',
  [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0x3d502e29b7076e11',
  [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
  [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
  [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
  [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0xf086a545ce3c552d',
  [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xceb10c84a8a7f414',
  [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
  [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
  [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xfcb06a5ae5b21a2d',
  [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e'
}

const CONTRACT_ADDRESSES: { [chainId in ChainId]: ContractAddressList } = {
  [ChainId.MAINNET]: {
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xcfdd90d4a00f7b5b',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xc6c77b9f5c7a378f',
    [ContractId.FLOW_USD_ADDRESS]: '0x3c5959b568896393',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x08f9e2ed8b0d548a',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x73d494fc6fe4b127',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0x78fea665a361cf0e',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0xe467b9dd11fa00df',
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xfcb06a5ae5b21a2d',
    [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e'
  },
  [ChainId.RINKEBY]: {
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0x9a0766d93b6608b7',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x7e60df042a9c0868',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xab26e0a07d770ec1',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x3502a5dacaf350bb',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x83cbadbe67c9ac0c',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xceb10c84a8a7f414',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x6e0797ac987005f5',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xc59604d4e65f14b3',
    [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x967a0fb3c949cbc5'
  },
  [ChainId.GÖRLI]: FAKE_ADDRESSES,
  [ChainId.KOVAN]: FAKE_ADDRESSES,
  [ChainId.ROPSTEN]: FAKE_ADDRESSES
}

export const replaceContractAddresses = (script: string, env: ChainId): string => {
  if (!script) {
    return script
  }

  const addresses = CONTRACT_ADDRESSES[env] || {}

  return script
    .replace('0xFUNGIBLETOKENADDRESS', addresses[ContractId.FUNGIBLE_TOKEN_ADDRESS])
    .replace('0xFLOWTOKENADDRESS', addresses[ContractId.FLOW_TOKEN_ADDRESS])
    .replace('0xTELEPORTEDUSDTADDRESS', addresses[ContractId.TELEPORTED_USDT_ADDRESS])
    .replace('0xFLOWSWAPPAIRADDRESS', addresses[ContractId.FLOW_SWAP_PAIR_ADDRESS])
    .replace('0xFUSDADDRESS', addresses[ContractId.FLOW_USD_ADDRESS])
    .replace('0xFUSDUSDTSWAPPAIRADDRESS', addresses[ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS])
    .replace('0xTOKENPOOLADDRESS', addresses[ContractId.TOKEN_POOL_ADDRESS])
    .replace('0xTELEPORTBLTADMINADDRESS', addresses[ContractId.TELEPORT_BLT_ADMIN_ADDRESS])
    .replace('0xTELEPORTUSDTADMINADDRESS', addresses[ContractId.TELEPORT_USDT_ADMIN_ADDRESS])
    .replace('0xFLOWSTORAGEFEESADDRESS', addresses[ContractId.FLOW_STORAGE_FEES_ADDRESS])
    .replace('0xBLTADDRESS', addresses[ContractId.BLOCTO_TOKEN_ADDRESS])
    .replace('0xBLTUSDTSWAPPAIRADDRESS', addresses[ContractId.BLT_USDT_SWAP_PAIR_ADDRESS])
    .replace('0xTELEPORTSOLANAADDRESS', addresses[ContractId.TELEPORT_SOLANA_ADDRESS])
    .replace('0xTELEPORTBSCBLTADDRESS', addresses[ContractId.TELEPORT_BSC_BLT_ADDRESS])
}
