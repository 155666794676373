const script = `\
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS).getCapability(/public/teleportedTetherTokenTeleportUser)
        .borrow<&TeleportedTetherToken.TeleportAdmin{TeleportedTetherToken.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- vault, to: targetAddress.decodeHex())
  }
}
`

export default script
