import { useMemo } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@uniswap/sdk'
import { useBloctoWeb3 } from './useBloctoWeb3'

import { Trade, useFclReact } from '../fcl-react'
import { ApprovalState } from './useApproveCallback'
import { TeleportCallbackState } from './useTeleportCallback'
import { useActiveWeb3React } from '.'
import { TELEPORT_ADDRESS } from '../constants'
import { useTeleportContract, useTokenContract } from './useContract'
import { useHasPendingApproval } from '../state/transactionsEthereum/hooks'
import { useTokenAllowance } from '../data/Allowances'
import { calculateGasMargin } from '../utils'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'
import { Network } from '../types'

interface ReturnValue {
  state: TeleportCallbackState
  callback: (() => Promise<string>) | null
  error: string | null
}

// returns a callback that batches approve & teleport transaction
export function useApproveAndTeleportCallback(trade?: Trade): ReturnValue {
  const web3 = useBloctoWeb3()
  const BatchRequest = web3?.BatchRequest
  const { account: ethAccount, chainId } = useActiveWeb3React()
  const { account: flowAccount } = useFclReact()

  const addTransaction = useTransactionAdder()

  const token = trade?.inputCurrency
  const spender = TELEPORT_ADDRESS[chainId ?? ChainId.MAINNET]

  const teleportContract = useTeleportContract()
  const tokenContract = useTokenContract(token?.address)

  const amountToApprove = BigNumber.from(trade?.inputAmount.toFixed(trade.inputCurrency.decimals).replace('.', '') ?? 0)
  const currentAllowance = useTokenAllowance(token, ethAccount ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])

  // check callback depedencies error
  const error = useMemo(() => {
    switch (true) {
      case !chainId:
        return 'Invalid chainId'
      case !trade:
        return 'No trade instance'
      case !BatchRequest:
        return 'BatchRequest is not supported'
      case !ethAccount || !flowAccount:
        return 'Missing dependencies'
      case !teleportContract || !tokenContract:
        return 'Web3 not supported'
      case approvalState !== ApprovalState.NOT_APPROVED:
        return 'Approve was called unnecessarily'
      case !token:
        return 'No token'
      case amountToApprove.eq(0):
        return 'Missing amount to approve'
      case !spender:
        return 'No spender'
      default:
        return null
    }
  }, [
    chainId,
    trade,
    BatchRequest,
    ethAccount,
    flowAccount,
    teleportContract,
    tokenContract,
    approvalState,
    token,
    amountToApprove,
    spender
  ])

  return useMemo(() => {
    if (error) {
      return { state: TeleportCallbackState.INVALID, callback: null, error }
    }

    return {
      state: TeleportCallbackState.VALID,
      callback: async function approveAndTeleport() {
        const batch = BatchRequest ? new BatchRequest() : null

        if (!batch) {
          throw new Error('Failed to create batch')
        }

        try {
          // calculate transactions gas
          let useExact = false
          const estimatedApproveGas = await tokenContract?.estimateGas.approve(spender, MaxUint256).catch(() => {
            // general fallback for tokens who restrict approval amounts
            useExact = true
            return tokenContract.estimateGas.approve(spender, amountToApprove.toString())
          })

          const sendTransaction = web3?.eth.sendTransaction as any

          // add approve request and promisify
          const approvePromise = new Promise((resolve, reject) => {
            const req = sendTransaction.request(
              {
                from: ethAccount,
                to: token?.address,
                data: tokenContract?.interface.encodeFunctionData('approve', [
                  spender,
                  (useExact ? amountToApprove : MaxUint256).toHexString()
                ]),
                gasLimit: calculateGasMargin(estimatedApproveGas ?? BigNumber.from(0)).toString()
              },
              (err: any, data: string) => {
                if (err) reject(err)
                else resolve(data)
              }
            )
            batch.add(req)
          })
          // add teleport request and promisify
          const teleportPromise = new Promise((resolve, reject) => {
            const req = sendTransaction.request(
              {
                from: ethAccount,
                to: TELEPORT_ADDRESS[chainId ?? ChainId.MAINNET],
                data: teleportContract?.interface.encodeFunctionData('lock', [
                  amountToApprove.toHexString(),
                  flowAccount
                ])
              },
              (err: any, data: string) => {
                if (err) reject(err)
                else resolve(data)
              }
            )
            batch.add(req)
          })
          batch.execute()

          // await for batched request to finish
          const responses = await Promise.all([approvePromise, teleportPromise])

          const inputSymbol = trade?.inputCurrency.symbol
          const inputAmount = trade?.inputAmount.toFixed(4)
          const CHAIN_NAME = {
            FLOW: 'Flow',
            BSC: 'Binance Smart Chain',
            ETHEREUM: 'Ethereum',
            SOLANA: 'Solana'
          }

          const inputChain = CHAIN_NAME[trade?.inputCurrency.network ?? Network.ETHEREUM]
          const outputChain = CHAIN_NAME[trade?.outputCurrency.network ?? Network.ETHEREUM]

          const summary = `Approve & Teleport ${inputAmount} ${inputSymbol} from ${inputChain} to ${outputChain}`

          const response: string = (responses[0] as string) || ''

          addTransaction({ transactionId: response }, { summary })

          return response
        } catch (error) {
          // if the user rejected the tx, pass this along
          if (error?.code === 4001) {
            throw new Error('Transaction rejected.')
          } else {
            // otherwise, the error was unexpected and we need to convey that
            console.error(`Teleport failed`, error)
            throw new Error(`Teleport failed: ${error.message}`)
          }
        }
      },
      error: null
    }
  }, [
    BatchRequest,
    web3,
    trade,
    error,
    chainId,
    token,
    ethAccount,
    flowAccount,
    tokenContract,
    teleportContract,
    amountToApprove,
    spender,
    addTransaction
  ])
}
