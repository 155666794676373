// Wrong vault type, destroy it
// destroy signer.load<@AnyResource>(from: TeleportedTetherToken.TokenStoragePath)
// signer.unlink(TeleportedTetherToken.TokenPublicReceiverPath)
// signer.unlink(TeleportedTetherToken.TokenPublicBalancePath)

const scriptFlowToExactUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = FlowSwapPair.quoteSwapToken1ForExactToken2(amount: amountOut) / (1.0 - FlowSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptFlowToExactFusd = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut)
    let amountIn = FlowSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt) / (1.0 - FlowSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault
    let token2Vault <- flowUsdtSwapProxy.swapToken1ForToken2(from: <-token1Vault)
    let token3Vault <- fusdUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    if signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")

    fusdVault.deposit(from: <- token3Vault)
  }
}
`

const scriptFlowToExactBlt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut)
    let amountIn = FlowSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt) / (1.0 - FlowSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")
    
    let token1Vault <- flowVault.withdraw(amount: amountIn) as! @FlowToken.Vault
    let token2Vault <- flowUsdtSwapProxy.swapToken1ForToken2(from: <-token1Vault)
    let token3Vault <- bltUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
        ?? panic("Could not borrow a reference to Vault")

    bloctoTokenVault.deposit(from: <- token3Vault)
  }
}
`

const scriptUsdtToExactFlow = `\
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut) / (1.0 - FlowSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")
    
    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    flowVault.deposit(from: <- token1Vault)
  }
}
`

const scriptUsdtToExactFusd = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FUSD from 0xFUSDADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    if signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
      ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    fusdVault.deposit(from: <- token1Vault)
  }
}
`

const scriptUsdtToExactBlt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut) / (1.0 - BltUsdtSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let token2Vault <- tetherVault.withdraw(amount: amountIn) as! @TeleportedTetherToken.Vault

    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }

    let bltVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token1Vault <- swapProxyRef.swapToken2ForToken1(from: <-token2Vault)

    bltVault.deposit(from: <- token1Vault)
  }
}
`

const scriptFusdToExactUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import FUSD from 0xFUSDADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountOut)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptFusdToExactFlow = `\
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut) / (1.0 - FlowSwapPair.feePercentage)
    let amountIn = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault
    let token2Vault <- fusdUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)
    let token1Vault <- flowUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    flowVault.deposit(from: <- token1Vault)
  }
}`

const scriptFusdToExactBlt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import FUSD from 0xFUSDADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = BltUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut) / (1.0 - BltUsdtSwapPair.feePercentage)
    let amountIn = FusdUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- fusdVault.withdraw(amount: amountIn) as! @FUSD.Vault
    let token2Vault <- fusdUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)
    let token1Vault <- bltUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    bloctoTokenVault.deposit(from: <- token1Vault)
  }
}
`

const scriptBltToExactUsdt = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountIn = BltUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountOut) / (1.0 - BltUsdtSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let bltVault = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
    
    let token1Vault <- bltVault.withdraw(amount: amountIn) as! @BloctoToken.Vault

    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      // Create a new teleportedTetherToken Vault and put it in storage
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }

    let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let swapProxyRef = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let token2Vault <- swapProxyRef.swapToken1ForToken2(from: <-token1Vault)

    tetherVault.deposit(from: <- token2Vault)
  }
}
`

const scriptBltToExactFlow = `\
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = FlowSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut) / (1.0 - FlowSwapPair.feePercentage)
    let amountIn = BltUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    let flowUsdtSwapProxy = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
        ?? panic("Could not borrow a reference to proxy holder")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let token3Vault <- bloctoTokenVault.withdraw(amount: amountIn) as! @BloctoToken.Vault
    let token2Vault <- bltUsdtSwapProxy.swapToken1ForToken2(from: <-token3Vault)
    let token1Vault <- flowUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    let flowVault = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    flowVault.deposit(from: <- token1Vault)
  }
}
`

const scriptBltToExactFusd = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
import BloctoToken from 0xBLTADDRESS
import FUSD from 0xFUSDADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

transaction(maxAmountIn: UFix64, amountOut: UFix64) {
  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    let amountUsdt = FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amountOut)
    let amountIn = BltUsdtSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt) / (1.0 - BltUsdtSwapPair.feePercentage)

    assert(amountIn < maxAmountIn, message: "Input amount too large")

    let bloctoTokenVault = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    let bltUsdtSwapProxy = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    let fusdUsdtSwapProxy = proxyHolder.borrow<&FusdUsdtSwapPair.SwapProxy>(from: /storage/fusdUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")
    
    let token1Vault <- bloctoTokenVault.withdraw(amount: amountIn) as! @BloctoToken.Vault
    let token2Vault <- bltUsdtSwapProxy.swapToken1ForToken2(from: <-token1Vault)
    let token3Vault <- fusdUsdtSwapProxy.swapToken2ForToken1(from: <-token2Vault)

    if signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) == nil {
      // Create a new FUSD Vault and put it in storage
      signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
      )
    }

    let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
        ?? panic("Could not borrow a reference to Vault")

    fusdVault.deposit(from: <- token3Vault)
  }
}
`

const sciprts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptFlowToExactUsdt,
    FUSD: scriptFlowToExactFusd,
    BLT: scriptFlowToExactBlt
  },
  tUSDT: {
    FLOW: scriptUsdtToExactFlow,
    FUSD: scriptUsdtToExactFusd,
    BLT: scriptUsdtToExactBlt
  },
  FUSD: {
    tUSDT: scriptFusdToExactUsdt,
    FLOW: scriptFusdToExactFlow,
    BLT: scriptFusdToExactBlt
  },
  BLT: {
    FLOW: scriptBltToExactFlow,
    tUSDT: scriptBltToExactUsdt,
    FUSD: scriptBltToExactFusd
  }
}

export default sciprts
