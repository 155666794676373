const scriptFlowUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &FlowSwapPair.Vault

  // The proxy holder reference for access control
  let swapProxyRef: &FlowSwapPair.SwapProxy

  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: &FlowToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    self.liquidityTokenRef = signer.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.swapProxyRef = proxyHolder.borrow<&FlowSwapPair.SwapProxy>(from: /storage/flowUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    self.flowTokenVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @FlowSwapPair.Vault

    // Take back liquidity
    let tokenBundle <- self.swapProxyRef.removeLiquidity(from: <-liquidityTokenVault)

    // Deposit liquidity tokens
    self.flowTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.tetherVaultRef.deposit(from: <- tokenBundle.withdrawToken2())

    destroy tokenBundle
  }
}
`

const scriptBltUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &BltUsdtSwapPair.Vault

  // The proxy holder reference for access control
  let swapProxyRef: &BltUsdtSwapPair.SwapProxy

  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenVaultRef: &BloctoToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  prepare(signer: AuthAccount, proxyHolder: AuthAccount) {
    self.liquidityTokenRef = signer.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.swapProxyRef = proxyHolder.borrow<&BltUsdtSwapPair.SwapProxy>(from: /storage/bltUsdtSwapProxy)
      ?? panic("Could not borrow a reference to proxy holder")

    self.bloctoTokenVaultRef = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @BltUsdtSwapPair.Vault

    // Take back liquidity
    let tokenBundle <- self.swapProxyRef.removeLiquidity(from: <-liquidityTokenVault)

    // Deposit liquidity tokens
    self.bloctoTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.tetherVaultRef.deposit(from: <- tokenBundle.withdrawToken2())

    destroy tokenBundle
  }
}
`

// const scriptFusdUsdt = `
// import FungibleToken from 0xFUNGIBLETOKENADDRESS
// import FUSD from 0xFUSDADDRESS
// import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
// import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

// transaction(amount: UFix64) {
//   prepare(signer: AuthAccount) {
//     let flowSwapPairVault = signer.borrow<&FusdUsdtSwapPair.Vault>(from: FusdUsdtSwapPair.TokenStoragePath)
//         ?? panic("Could not borrow a reference to Vault")

//     let liquidityTokenVault <- flowSwapPairVault.withdraw(amount: amount) as! @FusdUsdtSwapPair.Vault
//     let tokenBundle <- FusdUsdtSwapPair.removeLiquidity(from: <-liquidityTokenVault)

//     let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
//         ?? panic("Could not borrow a reference to Vault")

//     let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
//         ?? panic("Could not borrow a reference to Vault")

//     fusdVault.deposit(from: <- tokenBundle.withdrawToken1())
//     tetherVault.deposit(from: <- tokenBundle.withdrawToken2())

//     destroy tokenBundle
//   }
// }
// `

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptFlowUsdt
  },
  tUSDT: {
    FLOW: scriptFlowUsdt,
    BLT: scriptBltUsdt
  },
  BLT: {
    tUSDT: scriptBltUsdt
  }
}

export default scripts
