import { Currency } from '@uniswap/sdk'
import { TokenInfoName } from '../../types'
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { CloseIcon } from '../../theme'
import Column from '../Column'
import { RowBetween } from '../Row'
import CurrencyList from './TeleportCurrencyList'
import NetworkList from './NetworkList'
import SortButton from './SortButton'
import { PaddedColumn, SearchInput, Separator, TokenName } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TELEPORT_TOKENS, TELEPORT_SELECTIONS } from '../../constants/lists'
import { useFclReact } from '../../fcl-react'

interface TeleportSearchProps {
  isOpen: boolean
  onDismiss: () => void
  onSelect: (select: object) => void
  otherSelectedCurrency?: Currency | null
  isMainSelector?: boolean
}

export function TeleportSearch({
  onSelect,
  otherSelectedCurrency,
  onDismiss,
  isOpen,
  isMainSelector
}: TeleportSearchProps) {
  const { t } = useTranslation()
  const fixedList = useRef<FixedSizeList>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false)
  const [selectNetwork, setSelectNetwork] = useState<string[]>([])
  const selection = useRef<object>()
  const { chainId } = useFclReact()

  const handleSelect = useCallback(
    select => {
      onSelect(TELEPORT_TOKENS[select.name][select.network][chainId])
      onDismiss()
    },
    [onDismiss, onSelect, chainId]
  )

  const filteredSortedTokens: TokenInfoName[] = useMemo(() => {
    const filterSorted = TELEPORT_SELECTIONS.filter(token =>
      token.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    )
    if (invertSearchOrder) {
      filterSorted.reverse()
    }
    return filterSorted
  }, [invertSearchOrder, searchQuery])

  const filterSortedNetworks = useMemo(() => {
    const filterSorted = selectNetwork.filter(network => network.toLowerCase().includes(searchQuery.toLowerCase()))
    if (invertSearchOrder) {
      filterSorted.reverse()
    }
    return filterSorted
  }, [selectNetwork, invertSearchOrder, searchQuery])

  const handleCurrencySelect = useCallback(
    currency => {
      selection.current = { ...selection.current, name: currency.symbol }
      if (currency.networks.length > 1) {
        setSelectNetwork(currency.networks)
      } else {
        selection.current = { ...selection.current, network: currency.networks[0] }
        handleSelect(selection.current)
      }
    },
    [handleSelect]
  )

  const handleNetworkSelect = useCallback(
    (network: string) => {
      selection.current = { ...selection.current, network: network }
      handleSelect(selection.current)
    },
    [handleSelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(event => {
    const input = event.target.value
    setSearchQuery(input)
    fixedList.current?.scrollTo(0)
  }, [])

  return (
    <Column style={{ width: '100%', flex: '1 1' }}>
      <PaddedColumn gap="14px">
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            Select {selectNetwork.length ? 'blockchain' : 'a token'}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
      </PaddedColumn>

      <Separator />

      <PaddedColumn gap="14px">
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={t('tokenSearchPlaceholder')}
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
        />
        <RowBetween>
          <TokenName fontSize={14} fontWeight={500}>
            {selectNetwork.length ? 'Blockchain' : 'Token Name'}
          </TokenName>
          <SortButton ascending={invertSearchOrder} toggleSortOrder={() => setInvertSearchOrder(iso => !iso)} />
        </RowBetween>
      </PaddedColumn>

      <Separator />

      <div style={{ flex: '1' }}>
        <AutoSizer disableWidth>
          {({ height }) =>
            !selectNetwork.length ? (
              <CurrencyList
                height={height}
                tokens={filteredSortedTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                fixedListRef={fixedList}
                isMainSelector={isMainSelector}
              />
            ) : (
              <NetworkList
                height={height}
                networks={filterSortedNetworks}
                otherCurrency={otherSelectedCurrency}
                onNetworkSelect={handleNetworkSelect}
                fixedListRef={fixedList}
              />
            )
          }
        </AutoSizer>
      </div>
    </Column>
  )
}
