import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AlertTriangle } from 'react-feather'
// import { useToggleSettingsMenu } from '../../state/application/hooks'

const Outer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`
const Wrapper = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
`

// const Clickable = styled(Wrapper)`
//   cursor: pointer;
// `

const StyledAlert = styled(AlertTriangle)`
  margin-right: 12px;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.primary2};
`

function BetaWarning() {
  const { t } = useTranslation()
  // const toggleSettings = useToggleSettingsMenu()

  return (
    <Outer>
      {/* <Clickable onClick={toggleSettings}>
        <StyledAlert size={16} />
        <div>
          During the inital launch of FLOW/tUSDT, <br />
          there will be a lot of price movement. <br />
          You should always do your research, <br />
          and set proper <b>slippage tolerance</b>.
        </div>
      </Clickable> */}
      {/* <Wrapper>
        <StyledAlert size={16} />
        FLOW trading is disabled for users from US or Canada.
      </Wrapper> */}
      <Wrapper>
        <StyledAlert size={16} />
        {t('betaWarning')}
      </Wrapper>
    </Outer>
  )
}

export default BetaWarning
