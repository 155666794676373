import React, { useCallback } from 'react'
import { Trade } from '../../fcl-react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import TeleportModalFooter from './TeleportModalFooter'
import TeleportModalHeader from './TeleportModalHeader'

export default function ConfirmTeleportModal({
  trade,
  onConfirm,
  onDismiss,
  teleportErrorMessage,
  isOpen,
  attemptingTxn,
  txHash
}: {
  isOpen: boolean
  trade: Trade | undefined
  originalTrade: Trade | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  onAcceptChanges: () => void
  onConfirm: () => void
  teleportErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const modalHeader = useCallback(() => {
    return trade ? <TeleportModalHeader trade={trade} /> : null
  }, [trade])

  const modalBottom = useCallback(() => {
    return trade ? (
      <TeleportModalFooter onConfirm={onConfirm} trade={trade} teleportErrorMessage={teleportErrorMessage} />
    ) : null
  }, [onConfirm, teleportErrorMessage, trade])

  // text to show while loading
  const pendingText = `Teleporting ${trade?.inputAmount?.toString()} ${
    trade?.inputCurrency?.symbol
  } for ${trade?.outputAmount?.toString()} ${trade?.outputCurrency?.symbol}`

  const confirmationContent = useCallback(
    () =>
      teleportErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={teleportErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Teleport"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, teleportErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      trade={trade}
    />
  )
}
